export default function decodeShape(str, precision) {
  const coordinates = [];
  let latitude_change;
  let longitude_change;
  const factor = 10 ** (precision || 6);
  let index = 0;
  let lat = 0;
  let lng = 0;
  let shift = 0;
  let result = 0;
  let byte = null;

  // Coordinates have variable length when encoded, so just keep
  // track of whether we've hit the end of the string. In each
  // loop iteration, a single coordinate is decoded.
  while (index < str.length) {
    // Reset shift, result, and byte
    byte = null;
    shift = 0;
    result = 0;

    do {
      // eslint-disable-next-line no-plusplus
      byte = str.charCodeAt(index++) - 63;
      // eslint-disable-next-line no-bitwise
      result |= (byte & 0x1f) << shift;
      shift += 5;
    } while (byte >= 0x20);

    // eslint-disable-next-line no-bitwise
    latitude_change = result & 1 ? ~(result >> 1) : result >> 1;

    // eslint-disable-next-line no-multi-assign
    shift = result = 0;

    do {
      // eslint-disable-next-line no-plusplus
      byte = str.charCodeAt(index++) - 63;
      // eslint-disable-next-line no-bitwise
      result |= (byte & 0x1f) << shift;
      shift += 5;
    } while (byte >= 0x20);

    // eslint-disable-next-line no-bitwise
    longitude_change = result & 1 ? ~(result >> 1) : result >> 1;

    lat += latitude_change;
    lng += longitude_change;

    // coordinates.push([lat / factor, lng / factor]);
    coordinates.push([lng / factor, lat / factor]);
  }

  return coordinates;
}

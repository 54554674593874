import { css } from "styled-components";

import RubikBold from "../fonts/Rubik-Bold.ttf";
import RubikLight from "../fonts/Rubik-Light.ttf";
import RubikMedium from "../fonts/Rubik-Medium.ttf";
import RubikRegular from "../fonts/Rubik-Regular.ttf";
import RubikSemibold from "../fonts/Rubik-Semibold.ttf";

export const FontStyles = () =>
  css`
    @font-face {
      font-family: "Rubik";
      font-weight: 300;
      font-display: swap;
      src: url(${RubikLight}) format("truetype");
    }

    @font-face {
      font-family: "Rubik";
      font-weight: 400;
      font-display: swap;
      src: url(${RubikRegular}) format("truetype");
    }

    @font-face {
      font-family: "Rubik";
      font-weight: 500;
      font-display: swap;
      src: url(${RubikMedium}) format("truetype");
    }

    @font-face {
      font-family: "Rubik";
      font-weight: 600;
      font-display: swap;
      src: url(${RubikSemibold}) format("truetype");
    }

    @font-face {
      font-family: "Rubik";
      font-weight: 700;
      font-display: swap;
      src: url(${RubikBold}) format("truetype");
    }
  `;

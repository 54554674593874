const isValueExistInList = (list, value) =>
  list?.some((item) => item?.toString() === value?.toString());

const getColorFromDiagnostic = ({ diagnostic, fieldValue, colors }) => {
  const { grayAD } = colors;
  let color = grayAD;
  const { color_values, type } = diagnostic || {};

  if (type === "list") {
    color_values?.forEach(({ name: colorName, values }) => {
      if (isValueExistInList(values, fieldValue)) color = colors[colorName];
    });
  }

  if (type === "compare") {
    color_values?.forEach(({ name: colorName, values }) => {
      const [startValue, endValue] = values;

      if (!endValue && fieldValue >= startValue) {
        color = colors[colorName];
      }
      if (fieldValue >= startValue && fieldValue < endValue) {
        color = colors[colorName];
      }
    });
  }

  return color;
};

export default getColorFromDiagnostic;

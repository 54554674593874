import { ThemeProvider } from "styled-components";
import { theme } from "shared/styles";
import Loader from "shared/ui/loaders/Loader";
import importantObjects from "data/importantObjects.json";
import { NavigationPopupWrapper, RemoveDotUi, Select } from "./styled";

const DestinationsPopup = ({
  selected,
  navigation,
  isLoading,
  removePointer,
  onChange,
}) => (
  <ThemeProvider theme={theme}>
    <NavigationPopupWrapper>
      {!navigation.from && <span>Выберите первую точку на карте</span>}
      {navigation.from && !navigation.to && (
        <div>
          <Select onChange={onChange} defaultValue={selected}>
            <option value="" disabled>
              Выберите объект
            </option>
            {importantObjects.map(({ name }) => (
              <option value={name} key={name}>
                {name}
              </option>
            ))}
          </Select>
          {!selected && (
            <span>или выберите на карте конечную точку маршрута</span>
          )}
          <RemoveDotUi onClick={removePointer}>Удалить точку</RemoveDotUi>
        </div>
      )}
      {navigation.from && navigation.to && !navigation.length && (
        <div>
          {isLoading && <Loader position="static" />}
          {!isLoading && <span>Произошла ошибка при запросе</span>}
        </div>
      )}
      {navigation.length && (
        <div>
          <h3 style={{ textAlign: "center" }}>
            Расстояние: {navigation.length} км.
          </h3>
          <RemoveDotUi onClick={removePointer}>Удалить маршрут</RemoveDotUi>
        </div>
      )}
    </NavigationPopupWrapper>
  </ThemeProvider>
);

export default DestinationsPopup;

const getBaseLayersData = ({ params, currentGroup, defaultLayers }) => {
  const layersParam = params.get("layers");
  const currentLayersIds = layersParam?.split(",") || defaultLayers;
  const { layers } = currentGroup || {};

  const filteredLayers = layers?.filter(({ id }) =>
    currentLayersIds?.some((currentId) => +id === +currentId)
  );
  const currentLayers = currentLayersIds ? filteredLayers : layers;

  return currentLayers;
};

export default getBaseLayersData;

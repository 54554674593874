import styled, { css } from "styled-components";

export const CheckboxWrapper = styled.div(
  ({ theme, customStyles }) =>
    css`
      position: relative;
      height: 3.8rem;
      background-color: ${theme.colors.grayF4};
      border-radius: 3rem;
      border: 1px solid ${theme.colors.grayAD};
      box-shadow: inset 0 -0.13rem 0 0.03rem ${theme.colors.black15},
        0 0 0.13rem 0.13rem ${theme.colors.white30};
      cursor: pointer;

      &::after,
      &::before {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        color: ${theme.colors.grayAD};
        font-size: 0.6rem;
      }

      &::before {
        content: "Выкл.";
        top: 0.3rem;
      }

      &::after {
        content: "Вкл.";
        bottom: 0.3rem;
      }

      ${customStyles && customStyles(theme)}
    `
);

export const Checkbox = styled.input(
  ({ theme: { colors } }) => css`
    position: absolute;
    width: 0;
    height: 0;
    margin: 0;
    opacity: 0;

    &:checked + label {
      background-color: ${colors.red};
      color: ${colors.white};
      transform: translateY(68%);
    }
  `
);

export const Label = styled.label(
  ({ theme: { colors, zIndex } }) => css`
    position: relative;
    z-index: ${zIndex.contentMin};
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2.19rem;
    height: 2.19rem;
    padding: 0.3rem;
    border-radius: 50%;
    background-color: ${colors.green};
    color: ${colors.white};
    cursor: pointer;
    box-shadow: inset 0 -0.13rem 0 0.03rem ${colors.black15};
    transition: all 0.2s;
  `
);

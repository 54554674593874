import getAdditionalLayersData from "./getAdditionalLayersData";
import getBaseLayersData from "./getBaseLayersData";
import getDiagnosticData from "./getDiagnosticData";
import getGroupData from "./getGroupData";

const params = new URL(document.location).searchParams;

const getDefaultLayersData = ({ groups, infoLayers, defaultLayersData }) => {
  const { default_group_id: defaultGroupId, default_layers: defaultLayers } =
    defaultLayersData || {};
  const { currentGroupId, currentGroup } = getGroupData({
    params,
    groups,
    defaultGroupId,
  });
  const currentLayers = getBaseLayersData({
    params,
    currentGroup,
    defaultLayers,
  });
  const currentInfoLayers = getAdditionalLayersData({ params, infoLayers });
  const { diagnosticId, groupDiagnostic } = getDiagnosticData({
    params,
    currentGroup,
  });

  const isLayersParamsNotExist = !params.has("layers");

  return {
    currentLayers,
    currentGroupId,
    currentDiagnosticId: diagnosticId,
    currentGroupDiagnostic: groupDiagnostic,
    currentInfoLayers,
    isLayersParamsNotExist,
  };
};

export default getDefaultLayersData;

const getVisibleResourceIdsByName = ({ ngwMap, subString, separator }) => {
  const allLayerIds = ngwMap.getLayers();
  const visibleLayerIds = allLayerIds?.reduce((result, item) => {
    if (item.includes(subString)) {
      result.push(item.split(separator)[0]);
    }
    return result;
  }, []);

  return visibleLayerIds;
};

export default getVisibleResourceIdsByName;

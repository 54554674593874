import { nextGisGeoServiceHost } from "services/httpService";
import generateFeatureUrl from "../generateUrl";

const getDataByPosition = async ({
  lat,
  lng,
  successCallback,
  errorCallback,
}) => {
  try {
    const url = generateFeatureUrl({ lat, lng, types: "1,10" });
    const { data } = await nextGisGeoServiceHost.get(url);

    successCallback(data);
  } catch (error) {
    errorCallback(error);
    throw new Error(error);
  }
};

export default getDataByPosition;

import { Button } from "shared/ui/buttons";
import { ReactComponent as AnalyticsIcon } from "shared/icons/chart.svg";
import { ReactComponent as PhotoIcon } from "shared/icons/images.svg";
import { ANALYTICS, PHOTO } from "shared/constants/modalWindowNames";
import RouteDropdown from "../RouteDropdown";
import { ActionsWrapper } from "./styled";

const Actions = ({ dashboardList, photo, latitude, longitude, openModal }) => {
  const isActionsExist =
    dashboardList.length || (latitude && longitude) || photo;

  if (!isActionsExist) return null;

  return (
    <ActionsWrapper>
      {dashboardList.length > 0 && (
        <Button
          isIconButton
          name={ANALYTICS}
          title="Аналитика по объекту"
          onClick={openModal}
        >
          <AnalyticsIcon width="1rem" />
        </Button>
      )}

      {photo && (
        <Button
          isIconButton
          name={PHOTO}
          title="Фото объекта"
          onClick={openModal}
        >
          <PhotoIcon width="1rem" />
        </Button>
      )}

      <RouteDropdown latitude={latitude} longitude={longitude} />
    </ActionsWrapper>
  );
};
export default Actions;

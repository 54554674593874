import styled, { css } from "styled-components";

export const SparkLine = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 0.94rem;
`;

export const TooltipWrapper = styled.div(
  ({ theme: { colors, mapBoxShadow, borderRadius } }) => css`
    padding: 10px;
    border-radius: ${borderRadius};
    background-color: ${colors.white};
    box-shadow: ${mapBoxShadow};
    font-size: 14px;
    max-width: 180px;
  `
);

import styled, { css } from "styled-components";

export const TabWrapper = styled.section(
  ({ theme: { colors } }) => css`
    display: flex;
    flex-direction: column;
    height: 100%;
    color: ${colors.green};
  `
);

export const TabList = styled.div`
  position: relative;
  display: flex;
  flex: 1 0 auto;
  margin-bottom: -0.06rem;
`;

export const Content = styled.div(
  ({ theme: { colors } }) => css`
    display: flex;
    width: 100%;
    height: 100%;
    border-top: 0.06rem solid ${colors.grayAD};
  `
);

export const customDropdownStyles = () => css`
  &:last-child > ul {
    left: unset;
  }

  button {
    text-decoration: none;
    padding: 10px;
    border-radius: 5px 5px 0 0;
    height: 50px;
  }

  ul {
    top: 49px;
    left: 0;
    width: 200px;
  }
`;

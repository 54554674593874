import { css } from "styled-components";

export const customButtonStyles = ({ theme, customStyles }) => {
  const { zIndex } = theme;

  return css`
    position: absolute;
    top: 16px;
    right: 16px;
    z-index: ${zIndex.contentMin};
    width: 20px;
    height: 20px;
    padding: 0;
    color: ${theme.colors.lightGray};

    &:hover,
    &:focus {
      color: ${theme.colors.black};
      background-color: transparent !important;
    }

    svg {
      width: 100%;
      height: 100%;
    }

    ${customStyles && customStyles(theme)}
  `;
};

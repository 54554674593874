import styled, { css } from "styled-components";

export const SpinnerElement = styled.span(
  ({ theme, isLarge, customStyles }) => {
    const { colors, borderRadius } = theme;

    return css`
      width: 1.25rem;
      height: 1.25rem;
      border: solid 4px ${colors.green};
      border-left-color: transparent;
      border-radius: 50%;
      animation: rotation 1s linear infinite;
      ${isLarge &&
      css`
        width: 2.5rem;
        height: 2.5rem;
        border-width: 0.5rem;
      `}

      @keyframes rotation {
        0% {
          transform: rotate(0deg);
        }

        100% {
          transform: rotate(360deg);
        }
      }

      ${customStyles && customStyles(theme)}
    `;
  }
);

import styled, { css } from "styled-components";

export const Wrapper = styled.div(
  () => css`
    button:hover + div {
      display: block;
    }
  `
);

export const customButtonStyles = ({ mapBoxShadow }) => css`
  box-shadow: ${mapBoxShadow};
`;

export const Popup = styled.div(
  ({ theme: { colors, mapBoxShadow, borderRadius } }) => css`
    display: none;
    position: absolute;
    width: 200px;
    right: 0;
    bottom: 45px;
    padding: 10px;
    background: ${colors.white};
    border-radius: ${borderRadius};
    box-shadow: ${mapBoxShadow};
    font-size: 14px;
  `
);

import { useState, useRef } from "react";

import useClickOutside from "shared/hooks/useClickOutside";
import { getUserName, signOut } from "services/authService";
import { useModalsContext } from "context/ModalsContext";
import {
  DASHBOARDS,
  HELP,
  FEEDBACK,
  MANUAL,
} from "shared/constants/modalWindowNames";

import { Button, TextButton, LinkButton, CloseButton } from "shared/ui/buttons";
import { ReactComponent as Avatar } from "shared/icons/avatar.svg";
import { ReactComponent as Logo } from "shared/icons/tatneft.svg";
import { ReactComponent as Logout } from "shared/icons/logout.svg";
import { ReactComponent as MenuIcon } from "shared/icons/menu.svg";
import AllDashboards from "components/AllDashboards";
import Help from "components/Help";
import Feedback from "components/Feedback";
import Manual from "components/Manual";

import {
  DropdownText,
  Menu,
  MenuHeader,
  MenuItem,
  DropdownWrapper,
  MenuWrapper,
  DropdownOutside,
  buttonStyles,
  menuButtonStyles,
} from "./styled";

const MenuDropdown = () => {
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const dropdownRef = useRef(null);
  const { toggleModal } = useModalsContext();

  useClickOutside(dropdownRef, () => setIsDropdownVisible(false));

  const openModal = ({ currentTarget }) => {
    setIsDropdownVisible(false);
    const { name } = currentTarget;
    let content;
    switch (name) {
      case HELP:
        content = <Help />;
        break;
      case FEEDBACK:
        content = <Feedback />;
        break;
      case DASHBOARDS:
        content = <AllDashboards />;
        break;
      case MANUAL:
      default:
        content = <Manual />;
        break;
    }
    toggleModal(content);
  };

  const menuList = [
    {
      id: 1,
      name: DASHBOARDS,
      text: "Дашборды",
    },
    {
      id: 2,
      name: HELP,
      text: "Справка",
    },
    {
      id: 3,
      name: FEEDBACK,
      text: "Написать обращение",
    },
  ];

  return (
    <DropdownWrapper ref={dropdownRef}>
      <Button
        isIconButton
        isWhite
        title="Меню"
        customStyles={menuButtonStyles}
        onClick={() => setIsDropdownVisible((prev) => !prev)}
      >
        <MenuIcon width="1rem" height="1rem" />
      </Button>

      <MenuWrapper isDropdownVisible={isDropdownVisible}>
        <DropdownOutside
          onClick={() => setIsDropdownVisible((prev) => !prev)}
        />
        <Menu>
          <MenuHeader>
            <Logo />
            <CloseButton
              onClick={() => setIsDropdownVisible((prev) => !prev)}
            />
          </MenuHeader>

          {menuList.map(({ id, name, text }) => (
            <MenuItem key={id}>
              <TextButton
                name={name}
                customStyles={buttonStyles}
                onClick={openModal}
              >
                {text}
              </TextButton>
            </MenuItem>
          ))}

          <MenuItem>
            <LinkButton
              customStyles={buttonStyles}
              href="mailto:support@tatneft.ru"
            >
              Написать в поддержку
            </LinkButton>
          </MenuItem>

          <MenuItem>
            <TextButton
              customStyles={buttonStyles}
              name={MANUAL}
              onClick={openModal}
            >
              Инструкции
            </TextButton>
          </MenuItem>

          <div>
            <MenuItem>
              <Avatar />
              <DropdownText>{getUserName()}</DropdownText>
            </MenuItem>
            <MenuItem>
              <TextButton customStyles={buttonStyles} onClick={signOut}>
                <Logout stroke="currentColor" />
                Выход
              </TextButton>
            </MenuItem>
          </div>
        </Menu>
      </MenuWrapper>
    </DropdownWrapper>
  );
};

export default MenuDropdown;

import { useCallback, useEffect } from "react";

import { CloseButton } from "shared/ui/buttons";
import Portal from "../Portal";
import { Overlay, Wrapper } from "./styled";

const ModalWindow = ({ isOpen, close, children }) => {
  const closeWindow = useCallback((e) => close(e), [close]);

  useEffect(() => {
    const closeOnEscapeKey = (event) => {
      if (event.key === "Escape") closeWindow();
    };

    document.body.addEventListener("keydown", closeOnEscapeKey);

    return () => document.body.removeEventListener("keydown", closeOnEscapeKey);
  }, [closeWindow]);

  if (!isOpen) return null;

  return (
    <Portal wrapperId="modal-portal">
      <Overlay>
        <Wrapper>
          <CloseButton onClick={closeWindow} title="Закрыть (Esc)" />

          {children}
        </Wrapper>
      </Overlay>
    </Portal>
  );
};

export default ModalWindow;

import Spinner from "../Spinner";
import { LoaderWrapper, LoaderText } from "./styled";

const Loader = ({ text, position = "fixed", ...restProps }) => (
  <LoaderWrapper position={position} {...restProps}>
    <Spinner />
    {text && <LoaderText>{text}</LoaderText>}
  </LoaderWrapper>
);

export default Loader;

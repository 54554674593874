const getDashboardUrl = ({ dashboardId, filterParams = "" }) => {
  if (!dashboardId) return null;

  const dashboardBaseParams = "standalone=1&show_filters=1&expand_filters=0";
  const supersetDashboardParams = filterParams
    ? `${dashboardBaseParams}&${filterParams}`
    : dashboardBaseParams;

  const supersetUrl = process.env.REACT_APP_SUPERSET_URL;
  const url = `${supersetUrl}/superset/dashboard/${dashboardId}/?${supersetDashboardParams}`;

  return url;
};

export default getDashboardUrl;

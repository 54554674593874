const filterLayer = ({
  ngwMap,
  currentLayerId,
  searchString,
  searchFields,
  isEqualToSearchResult,
}) => {
  let searchResult = [];
  let foundedPoint;

  ngwMap.filterLayer(currentLayerId, (item) => {
    const {
      feature: { properties },
    } = item;
    let string = searchString?.toLowerCase();

    if (string.length === 0) return item;

    if (/азс\s\d+/.test(string))
      string = [string.slice(0, 4), "№", string.slice(4)].join("");

    const featureNames = Object.keys(properties)?.filter((key) =>
      searchFields.some((value) =>
        key.toLowerCase().includes(value.toLowerCase())
      )
    );

    const filteredFeatureValues = featureNames.reduce((result, name, index) => {
      const value = properties[name];
      const currentValue = value?.toString().toLowerCase();
      let isValueEqualToString = false;
      if (isEqualToSearchResult) {
        isValueEqualToString =
          name === "azs"
            ? `${properties.azs}, ${properties.naimenovanie_filiala}`.toLowerCase() ===
              string
            : currentValue === string;
      } else {
        isValueEqualToString =
          name === "azs"
            ? `${properties.azs}, ${properties.naimenovanie_filiala}`
                .toLowerCase()
                .includes(string)
            : currentValue?.includes(string);
      }
      if (isValueEqualToString) {
        result.push(
          value + (name === "azs" ? `, ${properties.naimenovanie_filiala}` : "")
        );
      }

      const isLastPoint = index === featureNames.length - 1;

      if (isLastPoint && result.length === 1) {
        foundedPoint = item;
      }

      return result;
    }, []);

    if (filteredFeatureValues.length > 0) {
      searchResult.push(...filteredFeatureValues);
      return item;
    }
  });

  if (!searchString) searchResult = [];

  if (foundedPoint && searchResult.length !== 1) {
    foundedPoint = null;
  }

  return {
    searchResult,
    foundedPoint,
  };
};

export default filterLayer;

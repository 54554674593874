import styled, { css } from "styled-components";

export const List = styled.ul(
  ({ theme: { down, breakpoints } }) => css`
    display: block;
    margin: 0;
    padding: 0;
    max-height: 18.75rem;
    overflow-y: auto;
    list-style: none;
    text-align: left;

    ${down(breakpoints.sm)} {
      max-height: 12.5rem;
    }
  `
);

export const ListItem = styled.li`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  font-size: 14px;
  white-space: break-spaces;

  strong {
    font-weight: 500;
    font-size: 16px;
  }
`;

import { useRef, useState } from "react";
import ReactNgwMapbox from "@nextgis/react-ngw-mapbox";

import { getToken } from "services/authService";
import { addMultipleLayers } from "services/mapService";
import {
  addLayersParamsToUrl,
  getDefaultLayersData,
} from "services/layersService";
import {
  NEXTGIS_BASE_URL,
  NEXTGIS_LOGIN,
  NEXTGIS_PASSWORD,
  KAZAN_COORDS,
  MIN_ZOOM,
  MAX_ZOOM,
} from "shared/constants/mapConstants";
import { useRosreestrPopupContext } from "context/RosreestrPopupContext";

import Loader from "shared/ui/loaders/Loader";
import MapPanel from "components/MapPanel";
import CurrentLocationButton from "components/CurrentLocationButton";
import TatneftLogo from "components/TatneftLogo";
import { RosreestrPopup } from "components/rosreestr";
import MapHelpButton from "components/MapHelpButton";
import MapWrapper from "./styled";

const NgwMap = ({ defaultLayersData, businessGroups, infoLayers }) => {
  const ngwMap = useRef(null);
  const [loading, setLoading] = useState(true);
  const {
    rosreestrDataVisible,
    setRosreestrDataVisible,
    rosreestrData,
    loading: rosreestrDataLoading,
  } = useRosreestrPopupContext();
  const {
    currentLayers,
    currentGroupId,
    currentInfoLayers,
    currentDiagnosticId,
    currentGroupDiagnostic,
    isLayersParamsNotExist,
  } = getDefaultLayersData({
    groups: businessGroups,
    infoLayers,
    defaultLayersData,
  });

  const addDefaultLayers = (map) => {
    if (isLayersParamsNotExist) {
      addLayersParamsToUrl({
        baseLayers: currentLayers,
        groupId: currentGroupId,
        diagnosticId: currentDiagnosticId,
      });
    }

    const layers = currentInfoLayers
      ? [...currentLayers, ...currentInfoLayers]
      : currentLayers;

    addMultipleLayers({
      ngwMap: map,
      layers,
      diagnostic: currentGroupDiagnostic,
    });
  };

  const token = getToken();
  const proxyBaseUrl = `${NEXTGIS_BASE_URL}/${token}/`;
  const mapOptions = {
    baseUrl: proxyBaseUrl,
    auth: { login: NEXTGIS_LOGIN, password: NEXTGIS_PASSWORD },
    // osm: true,
    // resources: [123],
    identification: true,
    controlsOptions: {
      ZOOM: { position: "bottom-right" },
      ATTRIBUTION: { position: "bottom-left" },
    },
    minZoom: MIN_ZOOM,
    maxZoom: MAX_ZOOM,
    center: KAZAN_COORDS,
    whenCreated: (map) => {
      map.addBaseLayer("TILE", {
        url: "https://tile1.maps.2gis.com/tiles?x={x}&y={y}&z={z}",
      });
      ngwMap.current = map;
      addDefaultLayers(map);
      setLoading(false);
    },
  };

  return (
    <MapWrapper>
      {loading && <Loader text="Загрузка данных" />}

      <ReactNgwMapbox
        {...mapOptions}
        // eslint-disable-next-line no-dupe-keys
        style={{ width: "100%", height: "100vh", height: "var(--app-height)" }}
      >
        <MapPanel
          groups={businessGroups}
          infoLayers={infoLayers}
          ngwMap={ngwMap.current}
          loading={loading}
          setLoading={setLoading}
          currentGroupId={currentGroupId}
          currentLayers={currentLayers}
          currentDiagnostic={currentGroupDiagnostic}
          currentInfoLayers={currentInfoLayers}
        />

        <CurrentLocationButton ngwMap={ngwMap.current} />
        <MapHelpButton ngwMap={ngwMap.current} />

        <TatneftLogo />

        {(rosreestrDataLoading || (rosreestrDataVisible && rosreestrData)) && (
          <RosreestrPopup
            close={() => setRosreestrDataVisible(false)}
            data={rosreestrData}
            loading={rosreestrDataLoading}
          />
        )}
      </ReactNgwMapbox>
    </MapWrapper>
  );
};

export default NgwMap;

import { getIconsFromDiagnostic } from "services/diagnosticService";
import loadImage from "../loadImage";

const loadMultipleImages = ({ ngwMap, icons, diagnostic }) => {
  const { default_icon: defaultIcon, additional_icons: additionalIcons } =
    icons || {};
  const diagnosticIcons = getIconsFromDiagnostic(diagnostic);

  const iconList = diagnostic
    ? diagnosticIcons
    : [defaultIcon, ...additionalIcons];

  iconList.forEach(({ name: iconName, file: iconUrl }) => {
    loadImage({ ngwMap, iconName, iconUrl });
  });
};

export default loadMultipleImages;

import { useRef } from "react";
import { useLayersContext } from "context/LayersContext";
import { addMultipleLayers } from "services/mapService";
import { RadioButton } from "shared/ui/formElements";

const DiagnosticsList = ({
  layers,
  diagnostics,
  checkedDiagnostic,
  setCheckedDiagnostic,
  removePreviouslySelectedLayers,
  saveCheckedLayers,
}) => {
  const lastItemId = useRef(Date.now());
  const { ngwMap, setLoading, checkedLayers } = useLayersContext();

  const onChange = ({ target }) => {
    const { value } = target;
    const isLastItem = +lastItemId.current === +value;
    const diagnostic = diagnostics.find(({ id }) => +id === +value);
    const currentDiagnostic = isLastItem ? null : diagnostic;
    let layersWithDiagnostic;

    if (!isLastItem) {
      layersWithDiagnostic = layers.filter(
        ({ diagnostics: layerDiagnostics }) =>
          layerDiagnostics.some(({ id }) => id === +value)
      );
    }

    const addingLayers = isLastItem ? checkedLayers : layersWithDiagnostic;

    setCheckedDiagnostic(currentDiagnostic);
    setLoading(true);

    removePreviouslySelectedLayers();
    saveCheckedLayers(addingLayers, currentDiagnostic?.id || 0);
    addMultipleLayers({
      ngwMap,
      layers: addingLayers,
      diagnostic: currentDiagnostic,
      callback: () => setLoading(false),
    });
  };

  return (
    <>
      {diagnostics.map(({ id, name }) => (
        <RadioButton
          key={id}
          id={id}
          value={id}
          name="diagnostics"
          data-testid="diagnostic-radio-button"
          checked={checkedDiagnostic?.id === id}
          onChange={onChange}
        >
          {name}
        </RadioButton>
      ))}

      <RadioButton
        id={lastItemId.current}
        value={lastItemId.current}
        name="diagnostics"
        data-testid="diagnostic-radio-button"
        checked={checkedDiagnostic === null}
        onChange={onChange}
      >
        Выкл
      </RadioButton>
    </>
  );
};

export default DiagnosticsList;

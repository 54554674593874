import usePosition from "shared/hooks/usePosition";
import Dropdown from "shared/ui/Dropdown";
import Spinner from "shared/ui/loaders/Spinner";
import { ReactComponent as RouteIcon } from "shared/icons/route.svg";
import { css } from "styled-components";

const customStyles = () => css`
  ul {
    top: unset;
    bottom: 0;
    right: -300%;
  }
`;

const RouteDropdown = ({ latitude, longitude }) => {
  const {
    latitude: currentLatitude,
    longitude: currentLongitude,
    error,
  } = usePosition();

  if (!latitude && !longitude) return null;

  const generateLink = ({ text, link }) => (
    <a href={link} target="_blank" rel="noreferrer noopener">
      {text}
    </a>
  );

  if (!currentLatitude && !currentLongitude && !error) return <Spinner />;

  const links = [
    {
      id: 1,
      item: generateLink({
        link: `https://yandex.ru/maps/?rtext=${currentLatitude},${currentLongitude}~${latitude},${longitude}&rtt=auto`,
        text: "Яндекс",
      }),
    },
    {
      id: 2,
      item: generateLink({
        link: `http://www.google.com/maps/dir/${currentLatitude},${currentLongitude}/${latitude},${longitude}`,
        text: "Google",
      }),
    },
  ];

  return (
    <Dropdown
      list={links}
      buttonType="icon"
      icon={<RouteIcon />}
      buttonText="Построить маршрут"
      customStyles={customStyles}
    />
  );
};

export default RouteDropdown;

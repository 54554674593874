import styled, { css } from "styled-components";

export const ButtonHtml = styled.button(
  ({ theme, isIconButton, isWhite, isActive, withShadow, customStyles }) => {
    const { colors, borderRadius } = theme;

    return css`
      position: relative;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      padding: 0.94rem 1.25rem;
      color: ${isWhite ? colors.gray : colors.white};
      border: 0;
      border-radius: ${borderRadius};
      background-color: ${isWhite ? "white" : colors.green};
      overflow: hidden;
      transition: all 0.5s ease;
      text-transform: uppercase;
      cursor: pointer;
      font-size: 1rem;
      font-weight: bold;
      line-height: 1.2;
      ${isIconButton &&
      css`
        width: 40px;
        height: 40px;
        padding: 0.3rem;
        border-radius: 50%;
      `}

      ${withShadow &&
      css`
        box-shadow: ${theme.mapBoxShadow};
      `}

      &:focus {
        outline: none;
      }

      &:focus-visible,
      &:hover {
        color: ${isWhite && "black"};
        background-color: ${!isWhite && colors.black};
      }

      ${isActive &&
      css`
        background-color: ${colors.green};
        color: white !important;

        &:hover {
          background-color: black !important;
        }
      `}

      ${customStyles && customStyles(theme)}
    `;
  }
);

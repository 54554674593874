import styled, { css } from "styled-components";
import { ReactComponent as CloseIconSvg } from "shared/icons/close.svg";

export const Message = styled.div(
  ({ theme, customStyles, isWarning, isError }) => {
    const { colors, borderRadius } = theme;
    const warningOrSuccessColor = isWarning ? colors.yellow : colors.green;
    const backgroundColor = isError ? colors.red : warningOrSuccessColor;
    const color = isWarning ? colors.black : colors.white;

    return css`
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding: 1rem 0.63rem;
      color: ${color};
      border-radius: ${borderRadius};
      background-color: ${backgroundColor};
      animation: appearance-with-slide 0.4s ease forwards;
      font-size: 0.9rem;

      @keyframes appearance-with-slide {
        0% {
          transform: translateX(-200%);
          opacity: 0;
        }

        50% {
          opacity: 0.5;
        }

        100% {
          transform: translateX(0);
          opacity: 1;
        }
      }

      @keyframes disappearance-with-slide {
        50% {
          opacity: 0.5;
        }

        100% {
          transform: translateX(-100vw);
          opacity: 0;
        }
      }

      &.closing {
        animation: disappearance-with-slide 0.4s ease forwards;
      }

      ${customStyles && customStyles(theme)};
    `;
  }
);

export const Content = styled.div`
  max-width: calc(100% - 1.53rem);
  word-break: break-word;
`;

export const MessageIcon = styled.div`
  &:not(:empty) {
    margin-right: 0.9rem;

    svg {
      width: 2.5rem;
      height: 2.5rem;
    }
  }
`;

export const CloseButton = styled.button(
  ({ theme: { colors, borderRadius } }) => css`
    display: flex;
    align-self: flex-start;
    margin-left: 0.3rem;
    padding: 0.2rem 0.3rem;
    color: inherit;
    border: 0;
    border-radius: ${borderRadius};
    background-color: transparent;
    transition: background-color 0.4s;
    line-height: 1rem;

    &:hover,
    &:focus {
      background-color: ${colors.black15};
      outline: none;
    }
  `
);

export const CloseIcon = styled(CloseIconSvg)`
  width: 0.63rem;
  height: 0.63rem;
`;

import styled, { css } from "styled-components";

export const Wrapper = styled.div`
  padding: 0 0.63rem;
`;

export const ListTitle = styled.h2`
  margin: 0 0 0.63rem;
  font-size: 1rem;
  font-weight: 600;
`;

export const toggleListStyles = () => css`
  padding: 0.63rem;

  button {
    justify-content: flex-start;
    font-size: 0.9rem;
  }
`;

import checkSelectedDataInList from "shared/utils/checkSelectedDataInList";
import addNewLayer from "../addNewLayer";

const addMultipleLayers = ({ ngwMap, layers = [], diagnostic, callback }) => {
  layers.forEach((layer) => {
    const { diagnostics } = layer;
    const diagnosticData = checkSelectedDataInList({
      list: diagnostics,
      checkedItem: diagnostic,
    });

    addNewLayer({
      ngwMap,
      layer,
      diagnostic: diagnostic ? diagnosticData : null,
    });
  });

  if (callback) callback();
};

export default addMultipleLayers;

import { MapControl } from "@nextgis/react-ngw-map";
import { ReactComponent as Logo } from "shared/icons/tatneft.svg";

const TatneftLogo = () => (
  <MapControl position="bottom-left">
    <Logo />
  </MapControl>
);

export default TatneftLogo;

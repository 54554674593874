import styled, { css } from "styled-components";

export const Wrapper = styled.div(
  ({ theme: { zIndex, down, breakpoints } }) => css`
    position: fixed;
    top: 0.63rem;
    left: 0.63rem;
    z-index: ${zIndex.max};
    display: flex;
    flex-direction: column;
    gap: 0.63rem;
    min-width: 14.5rem;
    max-width: 18.75rem;
    max-height: calc(100vh - 1.26rem);
    overflow: auto;

    ${down(breakpoints.sm)} {
      max-width: none;
      min-width: auto;
      width: calc(100vw - 1.26rem);
    }
  `
);

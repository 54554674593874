import styled, { css } from "styled-components";

export const TabButton = styled.button(
  ({ theme: { colors, borderRadius }, isActive }) => css`
    padding: 0.63rem;
    border: 0.06rem solid ${colors.grayAD};
    border-bottom: 0;
    border-radius: ${borderRadius} ${borderRadius} 0 0;
    background-color: ${colors.white};
    font-weight: bold;
    transition: all 0.2s;
    ${isActive &&
    css`
      border-color: ${colors.green};
      background-color: ${colors.green};
      color: ${colors.white};
    `}

    &:focus,
    &:hover {
      background-color: ${isActive ? colors.black : colors.grayEB};
    }
  `
);

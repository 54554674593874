import Popup from "shared/ui/Popup";
import RosReestrData from "../RosReestrData";

const RosreestrPopup = ({ close, data, loading }) => (
  <Popup close={close}>
    <RosReestrData rosReestrData={data} loading={loading} />
  </Popup>
);

export default RosreestrPopup;

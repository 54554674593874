import { css } from "styled-components";

// TODO:: use in elements with vertical scrolling
export const ScrollBarStyles = ({ theme: { colors } }) =>
  css`
    .scrollBar::-webkit-scrollbar {
      width: 0.63rem;
      transition: width 0.2s;
    }

    .scrollBar::-webkit-scrollbar:hover {
      width: 0.9rem;
    }

    .scrollBar::-webkit-scrollbar-track {
      background-color: transparent;
      transition: background-color 0.2s;
    }

    .scrollBar:hover::-webkit-scrollbar-track {
      background-color: ${colors.grayF4};
    }

    .scrollBar::-webkit-scrollbar-button {
      display: none;
    }

    .scrollBar::-webkit-scrollbar-thumb {
      border-radius: 0.63rem;
      background-color: transparent;
      transition: background-color 0.2s;
    }

    .scrollBar:hover::-webkit-scrollbar-thumb {
      border: 0.13rem solid ${colors.grayF4};
      background-color: rgb(0 0 0 / 40%);
    }
  `;

import Iframe from "shared/ui/Iframe";
import {
  generateFiltersParams,
  getDashboardUrl,
} from "services/supersetService";
import { iframeStyles } from "./styled";

const SupersetIframe = ({ dashboardId, filters = [], objectData }) => {
  if (!dashboardId) return null;

  const generatedParams =
    objectData && generateFiltersParams({ filters, objectData });

  const filterParams = generatedParams || "";
  const url = getDashboardUrl({ dashboardId, filterParams });

  if (!url) return null;

  return <Iframe src={url} title="Dashboard" customStyles={iframeStyles} />;
};

export default SupersetIframe;

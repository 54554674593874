import { Wrapper, Message, HomePageLink } from "./styled";

const ErrorScreen = () => (
  <Wrapper>
    <Message>Что-то пошло не так...</Message>
    <HomePageLink href="/">Вернуться в приложение</HomePageLink>
  </Wrapper>
);

export default ErrorScreen;

import styled, { css } from "styled-components";

const StyledButton = styled.button(
  ({ customStyles, theme, isActive }) => css`
    display: inline-flex;
    align-items: center;
    margin: 0;
    padding: 0;
    border: none;
    background-color: transparent;
    text-decoration: underline;
    transition: all 0.4s;
    cursor: pointer;
    font-family: "Rubik", sans-serif;

    &:focus {
      outline: none;
    }

    &:focus-visible,
    &:hover {
      color: ${theme.colors.green};
      background-color: transparent !important;
    }

    ${isActive &&
    css`
      background-color: ${theme.colors.green};
      color: white !important;

      &:hover {
        background-color: ${theme.colors.black} !important;
      }
    `}

    ${customStyles && customStyles(theme)};
  `
);

export default StyledButton;

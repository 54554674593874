export default {
  218010000000: "Территориальные зоны",
  218010010000: "Жилая зона",
  218010020000: "Общественно-деловая зона",
  218010030000:
    "Производственная зона, зона инженерной и транспортной инфраструктур",
  218010040000: "Зона сельскохозяйственного использования",
  218010050000: "Зона рекреационного назначения",
  218010060000: "Зона особо охраняемых территорий",
  218010070000: "Зона специального назначения",
  218010080000: "Зона размещения военных объектов",
  218010090000: "Иная зона",
  218020000000: "Зоны с особыми условиями использования территории",
  218020010000: "Зоны охраны природных объектов",
  218020010001: "Территория особо охраняемого природного объекта",
  218020010002: "Охранная зона особо охраняемого природного объекта",
  218020010003: "Водоохранная зона",
  218020010004: "Прибрежная защитная полоса",
  218020010005:
    "Зона санитарной охраны источников водоснабжения и водопроводов питьевого назначения",
  218020010006: "Особо ценные земли",
  218020020000: "Зоны охраны искусственных объектов",
  218020020001: "Территория объекта культурного наследия",
  218020020002: "Зона охраны объекта культурного наследия",
  218020020003: "Охранная зона геодезического пункта",
  218020020004:
    "Охранная зона стационарного пункта наблюдений за состоянием окружающей природной среды",
  218020020005: "Охранная зона транспорта",
  218020020006: "Охранная зона инженерных коммуникаций",
  218020020007:
    "Охранная зона линий и сооружений связи и линий и сооружений радиофикации",
  218020020008: "Придорожная полоса",
  218020030000: "Зоны защиты населения",
  218020030001: "Запретная зона при военном складе",
  218020030002: "Запретный район при военном складе",
  218020030003: "Загрязненные земли",
  218020030004: "Охранная зона загрязненных земель",
  218020030005:
    "Санитарно-защитная зона предприятий, сооружений и иных объектов",
  218020030006: "Санитарный разрыв (санитарная полоса отчуждения)",
  218020030007: "Зона ограничения от передающего радиотехнического объекта",
  218020030008: "Район падения отделяющихся частей ракет",
  218020040000: "Прочие зоны с особыми условиями использования территории",
  218020040001: "Зона публичного сервитута",
  218020040002: "Зона резервирования земель",
  218020040003: "Горный отвод",
  218020040004: "Пригородная зона",
  218020040005: "Зеленая зона",
  218020040006: "Особая экономическая зона",
  218020040007: "Пограничная зона",
  218020040008: "Территория традиционного природопользования",
  218020050000: "Иные зоны с особыми условиями использования территории",
  218020050001: "Иная зона с особыми условиями использования территории",
};

import styled, { css } from "styled-components";

export const CheckboxWrapper = styled.div(
  ({ theme, customStyles }) => css`
    position: relative;
    display: flex;
    user-select: none;
    font-size: inherit;
    padding: 4px 0;
    gap: 10px;

    ${customStyles && customStyles(theme)};
  `
);

/* z-index added because custom checkbox has ::before with transform property that adds context */
export const NativeCheckbox = styled.input(
  ({ theme: { colors, zIndex } }) => css`
    position: absolute;
    top: 0.13rem;
    left: 0;
    z-index: ${zIndex.contentMin};
    width: 0.94rem;
    height: 0.94rem;
    margin: 0;
    opacity: 0;
    cursor: pointer;

    & + span {
      background-color: transparent;
      border: 1px solid ${colors.lightGray};
      transition: background-color 0.3s;
    }

    &:checked + span {
      background: ${colors.green};
      background-size: 0.8rem;
      border: 1px solid ${colors.green};

      &::before {
        position: relative;
        top: -0.13rem;
        content: "";
        width: 0.2rem;
        height: 0.5rem;
        border-right: 0.13rem solid ${colors.white};
        border-bottom: 0.13rem solid ${colors.white};
        transform: rotate(35deg);
      }
    }

    &:hover {
      & ~ label {
        color: ${colors.green};
      }
    }
  `
);

export const CustomCheckbox = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  border-radius: 5px;
`;

export const Label = styled.label`
  display: flex;
  cursor: pointer;
`;

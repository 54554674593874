import { useCallback, useState } from "react";

import { useLayersContext } from "context/LayersContext";
import { useRosreestrPopupContext } from "context/RosreestrPopupContext";

import { getLayersForCadastralSearch } from "services/api";
import { addLayersParamsToUrl } from "services/layersService";
import {
  addDistanceLayers,
  addMultipleLayers,
  removeAllVisibleLayers,
  removeLayer,
} from "services/mapService";
import { getDataByCadastralNumber } from "services/nextGisGeoService";

import { DISTANCE_POSTFIX } from "shared/constants/layerIdPostfixes";
import { theme } from "shared/styles";

const useLayersForCadastralSearch = ({ ngwMap }) => {
  const { setCheckedLayers, setCheckedInfoLayers, setLoading } =
    useLayersContext();
  const {
    setRosreestrData,
    setRosreestrDataVisible,
    setLoading: setRosreestrDataLoading,
    setIsOpenFromSearch,
  } = useRosreestrPopupContext();
  const [cadastralLayersData, setCadastralLayersData] = useState();
  const [cadastralDataLayerId, setCadastralDataLayerId] = useState(null);
  const { yellow, green } = theme.colors;
  const distances = [100, 200];

  const prepareData = (data) => {
    const { business_objects, territory_data } = data;
    const { layers, id } = business_objects[0];
    return {
      baseLayers: layers,
      groupId: id,
      additionalLayers: territory_data,
    };
  };

  const fetchCadastralSearchLayers = useCallback(async () => {
    try {
      if (cadastralLayersData) return false;

      const data = await getLayersForCadastralSearch();
      const preparedData = prepareData(data);
      setCadastralLayersData(preparedData);
    } catch (error) {
      throw new Error(error);
    }
  }, [cadastralLayersData]);

  const enableBusinessLayers = () => {
    const { baseLayers, groupId, additionalLayers } = cadastralLayersData;

    addMultipleLayers({ ngwMap, layers: [...baseLayers, ...additionalLayers] });
    setCheckedLayers(baseLayers);
    setCheckedInfoLayers(additionalLayers);
    addLayersParamsToUrl({ baseLayers, additionalLayers, groupId });
  };

  const addLayersByCadastralData = async ({ data, id }) => {
    if (!data) return false;

    const cadastralDataLayer = await ngwMap?.addGeoJsonLayer({
      id,
      data,
      order: 1,
      fit: true,
      paint: { color: yellow, opacity: 0.5 },
      onAdded: () => setCadastralDataLayerId(id),
    });

    addDistanceLayers({
      ngwMap,
      layerGeoJson: cadastralDataLayer,
      distances,
      colors: { smallDistanceColor: yellow, largeDistanceColor: green },
    });
  };

  const getCadastralDataAndLayers = async (searchString) => {
    setRosreestrDataLoading(true);
    setRosreestrDataVisible(true);
    setIsOpenFromSearch(true);

    getDataByCadastralNumber({
      number: searchString,
      successCallback: (data) => {
        setRosreestrDataLoading(false);
        setRosreestrData(data?.features);

        removeAllVisibleLayers({ ngwMap });
        addLayersByCadastralData({ data, id: searchString });
        enableBusinessLayers();
        setLoading(false);
      },
      errorCallback: () => {
        setRosreestrData([]);
        setRosreestrDataLoading(false);
        setLoading(false);
      },
    });
  };

  const getCadastralDataLayerIds = () => [
    `${distances[0]}_${DISTANCE_POSTFIX}`,
    `${distances[1]}_${DISTANCE_POSTFIX}`,
    cadastralDataLayerId,
  ];

  const removeCadastralDataLayers = () => {
    if (cadastralDataLayerId) {
      getCadastralDataLayerIds().forEach((layerId) => {
        removeLayer({ ngwMap, layerId });
      });
      setCadastralDataLayerId(null);
      setIsOpenFromSearch(false);
    }
  };

  return {
    fetchCadastralSearchLayers,
    getCadastralDataAndLayers,
    removeCadastralDataLayers,
    enableBusinessLayers,
  };
};

export default useLayersForCadastralSearch;

import styled, { css } from "styled-components";

export const ButtonGroup = styled.div(
  ({ theme: { mapBoxShadow } }) => css`
    background-color: white;
    border-radius: 24px;
    box-shadow: ${mapBoxShadow};
  `
);

export const Wrapper = styled.div(
  ({ theme: { colors, mapBoxShadow } }) => css`
    display: flex;
    gap: 10px;
    position: relative;

    & button {
      overflow: unset;
      color: ${colors.lightGray};
    }

    & > button {
      box-shadow: ${mapBoxShadow};
    }
  `
);

export const ButtonBadge = styled.span(
  ({ theme: { colors } }) => css`
    position: absolute;
    top: -3px;
    right: -3px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 9px;
    outline: 1px solid white;
    background: ${colors.green};
    min-width: 18px;
    width: fit-content;
    padding: 0 3px;
    height: 18px;
    color: white;
    font-family: "Rubik", sans-serif;
    font-size: 14px;
  `
);

export const PanelWrapper = styled.aside(
  ({ theme: { colors, down, breakpoints, mapBoxShadow, borderRadius } }) => css`
    position: absolute;
    top: 50px;
    right: 0;
    padding: 16px 24px;
    width: 18.75rem;
    border-radius: ${borderRadius};
    background-color: ${colors.white};
    box-shadow: ${mapBoxShadow};
    transition: all 0.2s;

    ${down(breakpoints.sm)} {
      width: calc(100vw - 1.25rem);
      right: 0;
    }
  `
);

export const SectionsWrapper = styled.div`
  width: 100%;
  max-height: calc(min(var(--app-height) * 0.7, var(--app-height) - 150px));
  overflow-y: auto;
`;

export const Section = styled.section`
  &:not(:last-child) {
    margin-bottom: 1.25rem;
  }
`;

export const SectionTitle = styled.div`
  font-weight: 500;
  margin-bottom: 10px;
`;

import { FieldWrapper, FieldLabel, Field, ErrorMessage } from "./styled";

const Input = ({ id, name, type = "text", label, error, ...restProps }) => (
  <FieldWrapper>
    {label && <FieldLabel htmlFor={id || name}>{label}</FieldLabel>}

    <Field type={type} name={name} id={id || name} {...restProps} />
    {error && <ErrorMessage>{error}</ErrorMessage>}
  </FieldWrapper>
);

export default Input;

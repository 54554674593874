import { useMemo, useState } from "react";
import RosreestrPopupContext from "./RosreestrPopupContext";

const RosreestrPopupProvider = ({ children }) => {
  const [rosreestrDataVisible, setRosreestrDataVisible] = useState(false);
  const [rosreestrData, setRosreestrData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isOpenFromSearch, setIsOpenFromSearch] = useState(false);

  const context = useMemo(
    () => ({
      rosreestrDataVisible,
      setRosreestrDataVisible,
      rosreestrData,
      setRosreestrData,
      loading,
      setLoading,
      isOpenFromSearch,
      setIsOpenFromSearch,
    }),
    [
      rosreestrDataVisible,
      setRosreestrDataVisible,
      rosreestrData,
      setRosreestrData,
      loading,
      setLoading,
      isOpenFromSearch,
      setIsOpenFromSearch,
    ]
  );

  return (
    <RosreestrPopupContext.Provider value={context}>
      {children}
    </RosreestrPopupContext.Provider>
  );
};

export default RosreestrPopupProvider;

import { useEffect, useState, useCallback } from "react";
import { MapControl } from "@nextgis/react-ngw-map";

import { getDataByPosition } from "services/nextGisGeoService";
import {
  MIN_ZOOM,
  ZOOM_FOR_ROSREESTR_LAYERS,
} from "shared/constants/mapConstants";

import { Button } from "shared/ui/buttons";
import { ReactComponent as PointInfoIcon } from "shared/icons/point-information.svg";
import { useRosreestrPopupContext } from "context/RosreestrPopupContext";
import useLayersForCadastralSearch from "components/SearchField/useLayersForCadastralSearch";

import { customButtonStyles } from "./styled";

const RosreestrLayerButton = ({ ngwMap }) => {
  const { fetchCadastralSearchLayers, enableBusinessLayers } =
    useLayersForCadastralSearch({ ngwMap });

  const [zoom, setZoom] = useState(MIN_ZOOM);
  const [switchState, setSwitchState] = useState(false);
  const isZoomSmall = zoom < ZOOM_FOR_ROSREESTR_LAYERS;
  const {
    isOpenFromSearch,
    setRosreestrData,
    setRosreestrDataVisible,
    setLoading: setRosreestrDataLoading,
  } = useRosreestrPopupContext();

  useEffect(() => {
    fetchCadastralSearchLayers();
  }, [fetchCadastralSearchLayers]);

  const onZoomChange = useCallback(() => {
    setZoom(ngwMap?.getZoom());

    if (ngwMap?.getZoom() < ZOOM_FOR_ROSREESTR_LAYERS && !isOpenFromSearch) {
      setRosreestrData(null);
    }
  }, [ngwMap, setRosreestrData, isOpenFromSearch]);

  const toggleButton = async (checked) => {
    setSwitchState(checked);

    const allVisibleLayers = await ngwMap.getLayers();
    const isRosReestrLayerExist = allVisibleLayers.some((layer) =>
      layer.includes("rosreestr")
    );

    if (checked && !isRosReestrLayerExist) {
      enableBusinessLayers();
    }
  };

  const fetchData = useCallback(
    async (event) => {
      if (!switchState || event?.sourceType !== "raster") return;

      const allVisibleLayers = await ngwMap.getLayers();
      const isRosReestrLayerExist = allVisibleLayers.some((layer) =>
        layer.includes("rosreestr")
      );

      if (isRosReestrLayerExist) {
        const { lat, lng } = event.event.latLng;
        setRosreestrDataLoading(true);
        setRosreestrDataVisible(true);

        getDataByPosition({
          lat,
          lng,
          successCallback: (data) => {
            setRosreestrData(data?.features);
            setRosreestrDataLoading(false);
          },
          errorCallback: () => {
            setRosreestrDataLoading(false);
          },
        });
      }
    },
    [
      ngwMap,
      switchState,
      setRosreestrData,
      setRosreestrDataVisible,
      setRosreestrDataLoading,
    ]
  );

  useEffect(() => {
    ngwMap?.emitter.on("ngw:select", fetchData);
    ngwMap?.emitter.on("zoom", onZoomChange);

    return () => {
      ngwMap?.emitter.off("ngw:select", fetchData);
      ngwMap?.emitter.off("zoom", onZoomChange);
    };
  }, [ngwMap, fetchData, onZoomChange]);

  if (isZoomSmall) return null;

  return (
    <MapControl position="bottom-right">
      <Button
        isIconButton
        isWhite
        title="Идентификация слоев росреестра"
        isActive={switchState}
        onClick={() => toggleButton(!switchState)}
        customStyles={customButtonStyles}
      >
        <PointInfoIcon width="1.5rem" />
      </Button>
    </MapControl>
  );
};

export default RosreestrLayerButton;

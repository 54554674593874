import { useState } from "react";

import ToggleButton from "./ToggleButton";
import { DropdownItem, DropdownList, Wrapper } from "./styled";

const ToggleList = ({
  list = [],
  data,
  buttonText,
  customStyles,
  isOpen = false,
}) => {
  const [isListVisible, setIsListVisible] = useState(isOpen);

  return (
    <Wrapper customStyles={customStyles}>
      <ToggleButton
        buttonText={buttonText}
        isListVisible={isListVisible}
        setIsListVisible={setIsListVisible}
      />

      {isListVisible && !!list.length && !data && (
        <DropdownList>
          {list.map(({ id, item }) => (
            <DropdownItem key={id}>{item}</DropdownItem>
          ))}
        </DropdownList>
      )}

      {isListVisible && data && data}
    </Wrapper>
  );
};

export default ToggleList;

import { OBJECT_LAYER } from "shared/constants/layerIdPostfixes";
import generateLayerId from "../generateLayerId";
import getVisibleResourceIdsByName from "../getVisibleResourceIdsByName";
import removeLayer from "../removeLayer";

const removeVisibleObjectLayers = ({ ngwMap, layers = [] }) => {
  const visibleLayersResourceIds = getVisibleResourceIdsByName({
    ngwMap,
    subString: OBJECT_LAYER,
    separator: "_",
  });

  if (visibleLayersResourceIds.length === 0) return;

  layers.forEach(
    ({ resource_id: resourceId, is_territory_data: isInfoLayer, service }) => {
      if (visibleLayersResourceIds.some((id) => +id === +resourceId)) {
        const layerId = generateLayerId(resourceId, isInfoLayer, service);

        removeLayer({ ngwMap, layerId });
      }
    }
  );
};

export default removeVisibleObjectLayers;

import { useState, useEffect, useCallback } from "react";

import { getAllDashboards, getDashboardGroups } from "services/api";
import { useNotificationContext } from "context/NotificationContext";

import Loader from "shared/ui/loaders/Loader";
import { Button, LinkButton } from "shared/ui/buttons";
import GroupTitle from "shared/ui/GroupTitle";
import SupersetIframe from "components/SupersetIframe";
import {
  Wrapper,
  ListWrapper,
  List,
  listButtonStyles,
  DashboardWrapper,
  DashboardHeader,
  Arrow,
  backButtonStyles,
  Title,
  Group,
  SearchInput,
} from "./styled";

const AllDashboards = () => {
  const [allDashboards, setAllDashboards] = useState([]);
  const [dashboardGroups, setDashboardGroups] = useState([]);
  const [dashboardId, setDashboardId] = useState(null);
  const [dashboardName, setDashboardName] = useState("");
  const [loading, setLoading] = useState(true);
  const { addNotification } = useNotificationContext();
  const [searchField, setsearchField] = useState("");

  const fetchAllDashboards = useCallback(async () => {
    try {
      const data = await getAllDashboards();
      if (data) setAllDashboards(data);
    } catch (error) {
      addNotification({ content: error, type: "error" });
    }
  }, [addNotification]);

  const fetchDashboardGroups = useCallback(async () => {
    try {
      const data = await getDashboardGroups();
      if (data)
        setDashboardGroups(data.sort((a, b) => a.priority - b.priority));
    } catch (error) {
      addNotification({ content: error, type: "error" });
    }
  }, [addNotification]);

  const getFilteredDashboards = allDashboards.filter((dash) =>
    dash.name.toLowerCase().includes(searchField.toLowerCase())
  );

  const getDashboardsByIds = (ids) =>
    getFilteredDashboards.filter((dashboard) => ids.includes(dashboard.id));

  const getDashboardsWithoutGroup = getFilteredDashboards.filter(
    (dashboard) => {
      for (let i = 0; i < dashboardGroups.length; i += 1) {
        if (dashboardGroups[i].dashboard_list.includes(dashboard.id))
          return false;
      }
      return true;
    }
  );

  const showCurrentDashboard = (event, id, name) => {
    event.preventDefault();
    setDashboardId(id);
    setDashboardName(name);
  };

  const onChange = (event) => {
    setsearchField(event.target.value);
  };

  const getHighlightedText = (text, highlight) => {
    const parts = text.split(new RegExp(`(${highlight})`, "gi"));
    return (
      <span>
        {" "}
        {parts.map((part, i) => (
          <span
            key={i}
            style={
              part.toLowerCase() === highlight.toLowerCase()
                ? {
                    fontWeight: "bold",
                    backgroundColor: "#b6e3d5",
                  }
                : {}
            }
          >
            {part}
          </span>
        ))}{" "}
      </span>
    );
  };

  useEffect(() => {
    fetchAllDashboards();
    fetchDashboardGroups();
    setLoading(false);
  }, [fetchAllDashboards, fetchDashboardGroups]);

  return (
    <Wrapper>
      {loading && <Loader text="Загрузка данных" position="absolute" />}

      {allDashboards?.length > 0 && !dashboardId && (
        <ListWrapper>
          <SearchInput value={searchField} onChange={onChange} />
          {dashboardGroups?.map(({ dashboard_list, id, name }) => (
            <Group key={id}>
              {name && <GroupTitle>{name}</GroupTitle>}
              {getDashboardsByIds(dashboard_list)?.map((dashboard) => (
                <LinkButton
                  key={dashboard.id}
                  onClick={(e) =>
                    showCurrentDashboard(
                      e,
                      dashboard.superset_dashboard_id,
                      dashboard.name
                    )
                  }
                  customStyles={listButtonStyles}
                  href={`superset/${dashboard.superset_dashboard_id}?name=${dashboard.name}`}
                >
                  {searchField &&
                    getHighlightedText(dashboard.name, searchField)}
                  {!searchField && <span>{dashboard.name}</span>}
                </LinkButton>
              ))}
            </Group>
          ))}
          {getDashboardsWithoutGroup?.length > 0 && (
            <div>
              <GroupTitle>Без группы</GroupTitle>
              <List>
                {getDashboardsWithoutGroup?.map(
                  ({ superset_dashboard_id, id, name }) => (
                    <LinkButton
                      key={id}
                      onClick={(e) =>
                        showCurrentDashboard(e, superset_dashboard_id, name)
                      }
                      customStyles={listButtonStyles}
                      href={`superset/${superset_dashboard_id}?name=${name}`}
                    >
                      {name}
                    </LinkButton>
                  )
                )}
              </List>
            </div>
          )}
        </ListWrapper>
      )}

      {dashboardId && (
        <DashboardWrapper>
          <DashboardHeader>
            <Button
              isIconButton
              title="Назад к списку"
              customStyles={backButtonStyles}
              onClick={() => setDashboardId(null)}
            >
              <Arrow />
            </Button>

            <Title>{dashboardName}</Title>
          </DashboardHeader>

          {dashboardId && <SupersetIframe dashboardId={dashboardId} />}
        </DashboardWrapper>
      )}
    </Wrapper>
  );
};

export default AllDashboards;

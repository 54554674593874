import styled, { css } from "styled-components";

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: auto;
`;

export const Content = styled.div`
  max-width: 35rem;
  margin: 0 auto;
`;

export const SearchWrapper = styled.div(
  ({ theme: { colors, zIndex } }) => css`
    position: sticky;
    top: 0;
    z-index: ${zIndex.contentMin};
    margin-bottom: 0.94rem;
    padding: 0.1rem;
    background-color: ${colors.white};
  `
);

export const GroupsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Group = styled.section`
  &:not(:last-child) {
    margin-bottom: 0.94rem;
  }
`;

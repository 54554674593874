import { useEffect, useState } from "react";

export const usePosition = () => {
  const [position, setPosition] = useState({});
  const [error, setError] = useState(null);

  const onChange = ({ coords, timestamp }) => {
    const { latitude, longitude, accuracy, speed, heading } = coords;
    setPosition({
      latitude,
      longitude,
      accuracy,
      speed,
      heading,
      timestamp,
    });
  };

  const onError = (err) => {
    setError(err.message);
  };

  useEffect(() => {
    if (!navigator || !navigator.geolocation) {
      setError("Geolocation is not supported");
      return;
    }

    navigator.geolocation.getCurrentPosition(onChange, onError);
  }, []);

  return { ...position, error };
};

export default usePosition;

export const NEXTGIS_BASE_URL = process.env.REACT_APP_NEXTGIS_URL;
export const NEXTGIS_LOGIN = process.env.REACT_APP_NEXTGIS_LOGIN;
export const NEXTGIS_PASSWORD = process.env.REACT_APP_NEXTGIS_PASSWORD;
export const KAZAN_COORDS = [49.108891, 55.796391];
// Zoom of cadastral layers
export const MIN_ZOOM = 3;
export const MAX_ZOOM = 20;

export const ZOOM_FOR_ROSREESTR_LAYERS = 15;
export const ZOOM_FOR_NAVIGATION_BUTTON = 15;

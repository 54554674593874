const EARTHS_RADIUS = 6371;
const d2r = Math.PI / 180; // degrees to radians
const r2d = 180 / Math.PI; // radians to degrees

const properties = {
  green: { id: 1, color: "green", opacity: 0.15 },
  yellow: { id: 2, color: "yellow", opacity: 0.15 },
  red: { id: 3, color: "red", opacity: 0.15 },
};

const getCirclePolygonCoordinates = (lng, lat, radius = 10, points = 6) => {
  // find the radius in lat/lon
  const rlat = (radius / EARTHS_RADIUS) * r2d;
  const rlng = rlat / Math.cos(lat * d2r);

  const extp = [];
  for (let i = 0; i < points + 1; i += 1) {
    // one extra here makes sure we connect the

    const theta = Math.PI * (i / (points / 2));
    const ex = lng + rlng * Math.cos(theta); // center a + radius x * cos(theta)
    const ey = lat + rlat * Math.sin(theta); // center b + radius y * sin(theta)
    extp.push([ex, ey]);
  }

  return extp;
};

export const getCircleFeature = (
  lng,
  lat,
  color,
  radius = 1.5,
  points = 16
) => {
  const polygon = getCirclePolygonCoordinates(lng, lat, radius, points);
  const feature = {
    type: "Feature",
    geometry: {
      type: "Polygon",
      coordinates: [polygon],
    },
    properties: { ...properties[color] },
  };
  return feature;
};

export default getCircleFeature;

import styled, { css } from "styled-components";

export const Wrapper = styled.div(
  ({ theme, customStyles }) => css`
    position: relative;
    display: flex;
    flex-direction: column;
    ${customStyles && customStyles(theme)}
  `
);

export const DropdownList = styled.ul(
  ({ theme: { colors, borderRadius, zIndex } }) => css`
    position: absolute;
    top: calc(100% + 0.63rem);
    right: 0;
    overflow: hidden;
    z-index: ${zIndex.contentMin};
    width: 6.25rem;
    border-radius: ${borderRadius};
    border: 0.06rem solid ${colors.grayAD};
    background-color: ${colors.white};
    list-style: none;
  `
);

export const DropdownItem = styled.li`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0.63rem;
  font-size: 0.9rem;
`;

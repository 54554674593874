import styled, { css } from "styled-components";

export const Wrapper = styled.div(
  ({ theme, customStyles }) => css`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    ${customStyles && customStyles(theme)}
  `
);

export const DropdownList = styled.ul(
  ({ theme: { colors } }) => css`
    overflow: hidden;
    background-color: ${colors.white};
    list-style: none;
  `
);

export const DropdownItem = styled.li`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0.63rem;
  font-size: 0.9rem;
`;

export const ButtonText = styled.span`
  display: flex;
  max-width: calc(100% - 1.63rem);
`;

export const toggleButtonStyles = ({ isListVisible }) => css`
  justify-content: space-between;
  text-decoration: none;

  svg {
    width: 1rem;
    margin-left: 0.63rem;
    transition: all 0.2s;
    ${isListVisible &&
    css`
      transform: rotate(180deg);
    `}
  }
`;

import { List, ListItem } from "./styled";

const PropertiesList = ({ properties }) => (
  <List>
    {properties.map(({ id, name, value }) => (
      <ListItem key={id}>
        <strong>{name}</strong>
        <span>{value}</span>
      </ListItem>
    ))}
  </List>
);

export default PropertiesList;

export const colors = {
  layerDefaultColor: "#046307",
  black: "#000",
  black15: "rgb(0 0 0 / 15%)",
  white: "#fff",
  white30: "rgb(255 255 255 / 30%)",
  grayAD: "#adadad",
  grayEB: "#ebebeb",
  grayF4: "#f4f4f4;",
  // colors for diagnostics
  green: "#27A67E",
  red: "#dc4c4d",
  yellow: "#f1bf21",
  gray: "#333",
  lightestGray: "#f5f5f5",
  gray4: "#BDBDBD",
  border: "#E6E6E6",
  lightGray: "#828282",
};

export const breakpoints = {
  xs: 320,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
};

// Try to use existing z-index values instead of creating new ones
export const zIndex = {
  max: 5, // background loaders, notifications
  aboveContent: 4, // modals, popups, menu panels
  contentMax: 2,
  contentMin: 1,
  default: 0,
  hiddenContent: -1,
};

export const mapBoxShadow =
  "0 2px 4px rgba(0, 0, 0, 0.2), 0 -1px 0px rgba(0, 0, 0, 0.02)";

export const borderRadius = "10px";

export const up = (breakpoint, vertical = false) =>
  `@media (min-${vertical ? "height" : "width"}: ${breakpoint + 0.02}px)`;

export const down = (breakpoint, vertical = false) =>
  `@media (max-${vertical ? "height" : "width"}: ${breakpoint}px)`;

export const between = (breakpointMin, breakpointMax, vertical = false) =>
  `@media (max-${vertical ? "height" : "width"}: ${breakpointMax}px) and (min-${
    vertical ? "height" : "width"
  }: ${breakpointMin + 0.02}px)`;

const theme = {
  colors,
  breakpoints,
  borderRadius,
  zIndex,
  mapBoxShadow,
  up,
  down,
  between,
};

export default theme;

import styled, { css } from "styled-components";

export const Wrapper = styled.div(
  ({
    theme: { colors, borderRadius, down, breakpoints, zIndex },
    position = {},
  }) => {
    const { top, right, bottom, left } = position;

    return css`
      position: fixed;
      top: ${top || "auto"};
      right: ${right || "auto"};
      bottom: ${bottom || "0.63rem"};
      left: ${left || "0.63rem"};
      z-index: ${zIndex.aboveContent};
      overflow: hidden;
      width: 100%;
      max-width: 30rem;
      padding: 1.13rem 1rem 1rem;
      box-shadow: 0 0 0.4rem rgb(0 0 0 / 35%);
      border-radius: ${borderRadius};
      background-color: ${colors.white};
      font-size: 0.9rem;

      ${down(breakpoints.sm)} {
        max-width: calc(100% - 4.75rem);
      }
    `;
  }
);

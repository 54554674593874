import styled, { css } from "styled-components";

export const FieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

export const FieldLabel = styled.label`
  &:not(:empty) {
    margin-bottom: 0.5rem;
  }
`;

export const Field = styled.input(
  ({ theme: { colors } }) => css`
    display: flex;
    width: 100%;
    padding: 13px 20px;
    border-radius: 40px;
    border: 1px solid ${colors.gray4};
    font-size: 16px;
    font-family: Rubik, sans-serif;
    color: ${colors.gray};

    &::placeholder {
      color: ${colors.gray4};
    }

    &:hover,
    &:focus {
      border-color: ${colors.green};
      outline: none;
    }
  `
);

export const ErrorMessage = styled.span(
  ({ theme: { colors } }) => css`
    padding: 0.5rem 0;
    color: ${colors.green};
    font-size: 0.9rem;
  `
);

import { ReactComponent as ArrowIcon } from "shared/icons/arrow-down.svg";
import { TextButton } from "../buttons";
import { ButtonText, toggleButtonStyles } from "./styled";

const ToggleButton = ({ buttonText, isListVisible, setIsListVisible }) => (
  <TextButton
    isListVisible={isListVisible}
    onClick={() => setIsListVisible((prev) => !prev)}
    title={buttonText}
    customStyles={(theme) => toggleButtonStyles({ theme, isListVisible })}
  >
    {buttonText && <ButtonText>{buttonText}</ButtonText>}
    <ArrowIcon />
  </TextButton>
);

export default ToggleButton;

const getCompareExpression = ({ colorValues, columnName, defaultColor }) => {
  const result = [];

  colorValues?.forEach(({ name: color, values }) => {
    const [startValue, endValue] = values || [];

    /** Result:
      [
        "all",
        ["<", ["to-number", ["get", propertyName]], propertyValue[1]],
        [">=", ["to-number", ["get", propertyName]], propertyValue[0]],
      ],
      color
    */
    if ((startValue || startValue === 0) && endValue) {
      result.push([
        "all",
        ["<", ["to-number", ["get", columnName]], +endValue],
        [">=", ["to-number", ["get", columnName]], +startValue],
      ]);
      result.push(color);
    }

    /** Result:
      [">", ["to-number", ["get", propertyName]], propertyValue[0]],
      color
    */
    if (startValue && !endValue) {
      result.push([">=", ["to-number", ["get", columnName]], +startValue]);
      result.push(color);
    }
  });

  result.push(defaultColor);
  return result;
};

const getListExpression = ({ colorValues, columnName, defaultColor }) => {
  const result = [];

  /** Result (the `propertyValueColor` should be some color in HEX format (#fffff)):
    ["==", ["get", propertyName], propertyValue1],
    "propertyValue1Color",
    ["==", ["get", propertyName], propertyValue2],
    "propertyValue2Color",
  */
  colorValues?.forEach(({ name: color, values }) => {
    values?.forEach((value) => {
      result.push(["==", ["get", columnName], value]);
      result.push(color);
    });
  });

  result.push(defaultColor);
  return result;
};

const getColorsExpression = ({ colorData, defaultColor }) => {
  const {
    type,
    field_name: columnName,
    color_values: colorValues,
  } = colorData || {};
  const result = ["case"];

  const expressionFn =
    type === "compare" ? getCompareExpression : getListExpression;

  const expressionResult = expressionFn({
    colorValues,
    columnName,
    defaultColor,
  });

  result.push(...expressionResult);

  return colorValues?.length > 0 ? result : null;
};

export default getColorsExpression;

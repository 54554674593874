import { Item, ItemColor, ItemIcon, List } from "./styled";

const Legend = ({ vectorType, list }) => (
  <List>
    {list.map(({ icon, color, label }, index) => {
      if (label) {
        return (
          <Item key={index}>
            {icon && (
              <ItemIcon
                src={icon.file}
                alt={icon.name}
                vectorType={vectorType}
              />
            )}
            {color && <ItemColor color={color} vectorType={vectorType} />}

            <span>{label}</span>
          </Item>
        );
      }
      return null;
    })}
  </List>
);

export default Legend;

const clearValues = (filterValue) =>
  filterValue
    ?.split(",")
    .map((filter) => {
      const changedFilter = filter.replaceAll("'", "").trim();
      const isWithSpace = changedFilter.includes(" ");

      return isWithSpace ? `'${changedFilter}'` : changedFilter;
    })
    .join();

const generateFiltersParams = ({ filters = [], objectData = {} }) => {
  const getParams = ({ nativeFilterId, filterValue, columnName }) => {
    const isValueString = typeof filterValue === "string";
    const label = isValueString
      ? filterValue?.replaceAll("'", "")
      : filterValue;
    const value = isValueString ? clearValues(filterValue) : `'${filterValue}'`;

    if (filterValue) {
      return `${nativeFilterId}:(__cache:(label:'${label}',validateStatus:!f,value:!(${value})),extraFormData:(filters:!((col:${columnName},op:IN,val:!(${value})))),filterState:(label:'${label}',validateMessage:!f,validateStatus:!f,value:!(${value})),id:${nativeFilterId},ownState:())`;
    }

    return `${nativeFilterId}:(extraFormData:(),filterState:(),id:${nativeFilterId},ownState:())`;
  };

  const paramsArr = filters?.map(({ filter_id, field_name, value }) => {
    const filterValue = value || objectData.properties[field_name];

    const data = getParams({
      nativeFilterId: filter_id,
      filterValue,
      columnName: field_name,
    });

    return encodeURIComponent(data);
  });

  return `native_filters=(${paramsArr?.join()})`;
};

export default generateFiltersParams;

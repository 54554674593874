import styled, { css } from "styled-components";

export const RadioButtonWrapper = styled.div(
  ({ theme, customStyles }) => css`
    display: flex;
    user-select: none;
    font-size: inherit;
    padding: 4px 0;

    ${customStyles && customStyles(theme)};
  `
);

export const NativeRadioButton = styled.input(
  ({ theme: { colors } }) => css`
    margin: 0;
    opacity: 0;
    cursor: pointer;

    &:focus span {
      box-shadow: 0 0 0 0.2rem ${colors.grayEB};
    }

    &:hover ~ label {
      color: ${colors.green};
    }
  `
);

export const Label = styled.label`
  width: calc(100% - 1.5rem);
  cursor: pointer;
`;

export const CustomRadioButton = styled.span(
  ({ theme: { colors }, checked }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20px;
    width: 20px;
    margin-left: -0.8rem;
    margin-right: 0.6rem;
    border-radius: 50%;
    background-color: ${checked ? colors.green : "transparent"};
    border: 1px solid ${checked ? colors.green : colors.lightGray};

    &::after {
      content: "";
      display: block;
      width: 0.3rem;
      height: 0.3rem;
      background-color: ${colors.white};
      border-radius: 50%;
    }
  `
);

import { ReactComponent as CloseIcon } from "shared/icons/close.svg";
import Button from "../Button";
import { customButtonStyles } from "./styled";

const CloseButton = ({ isSmall, onClick, customStyles, ...restProps }) => (
  <Button
    isIconButton
    isWhite
    title="Закрыть"
    onClick={onClick}
    customStyles={(theme) =>
      customButtonStyles({ theme, customStyles, isSmall })
    }
    {...restProps}
  >
    <CloseIcon />
  </Button>
);

export default CloseButton;

import { useState, useEffect } from "react";

import { getLayerDashboardGroups, getLayerDashboards } from "services/api";
import { useNotificationContext } from "context/NotificationContext";

import { PHOTO } from "shared/constants/modalWindowNames";
import ModalWindow from "shared/ui/ModalWindow";
import LayerDashboards from "components/LayerDashboards";

import PropertiesList from "../PropertiesList";
import Actions from "../Actions";
import ImageViewer from "../ImageViewer";
import SparkLine from "../SparkLine";
import { Content, ListSection } from "./styled";

const PopupContent = ({
  layerId,
  currentObject,
  filteredFields = [],
  isInfoLayerPoint,
}) => {
  const [dashboardList, setDashboardList] = useState([]);
  const [dashboardGroups, setDashboardGroups] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [imageLoading, setImageLoading] = useState(false);

  const { properties } = currentObject || {};
  const { latitude, longitude, photos } = properties || {};
  const { addNotification } = useNotificationContext();

  const openModal = ({ currentTarget }) => {
    const { name } = currentTarget;

    setModalContent(name);
    setShowModal(true);
    if (name === PHOTO) setImageLoading(true);
  };

  const closeModal = () => {
    setModalContent(null);
    setShowModal(false);
  };

  useEffect(() => {
    if (!isInfoLayerPoint && layerId) {
      const getLayerDashboardList = async () => {
        try {
          const dashboards = await getLayerDashboards(layerId);

          if (dashboards) setDashboardList(dashboards);
        } catch (error) {
          addNotification({ content: error, type: "error" });
        }
      };
      const getGroups = async () => {
        try {
          const groups = await getLayerDashboardGroups(layerId);

          if (groups)
            setDashboardGroups(groups.sort((a, b) => a.priority - b.priority));
        } catch (error) {
          addNotification({ content: error, type: "error" });
        }
      };
      getLayerDashboardList();
      getGroups();
    }
  }, [isInfoLayerPoint, layerId, addNotification]);

  return (
    <>
      <Content>
        {filteredFields.length > 0 && (
          <ListSection>
            <PropertiesList properties={filteredFields} />
          </ListSection>
        )}

        <Actions
          dashboardList={dashboardList}
          photo={photos}
          latitude={latitude}
          longitude={longitude}
          openModal={openModal}
        />

        <SparkLine
          properties={properties}
          isInfoLayerPoint={isInfoLayerPoint}
        />
      </Content>

      <ModalWindow isOpen={showModal} close={closeModal}>
        {modalContent === PHOTO ? (
          <ImageViewer
            imageLoading={imageLoading}
            photos={photos ? JSON.parse(photos.replaceAll("'", '"')) : []}
            setImageLoading={setImageLoading}
          />
        ) : (
          <LayerDashboards
            objectData={currentObject}
            dashboardList={dashboardList}
            dashboardGroups={dashboardGroups}
          />
        )}
      </ModalWindow>
    </>
  );
};

export default PopupContent;

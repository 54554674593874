const getLayerCenter = (layer) => {
  const [x1, y1, x2, y2] = layer.getBounds();

  if (x2 && y2) {
    const centerX = x1 + (x2 - x1) / 2;
    const centerY = y1 + (y2 - y1) / 2;

    return [centerX, centerY];
  }

  return [x1, y1];
};

export default getLayerCenter;

import Item from "../Item";
import { DataList, AdditionalData, Value } from "./styled";
import getFilteredLists from "./getFilteredLists";
import { parcelsId, restrictedZonesId } from "./testIds";

const List = ({ list }) => {
  const viewFields = {
    cn: "Кадастровый номер",
    address: "Адрес",
    util_by_doc: "Разрешенное использование по документу",
    category_type: "Тип категории",
    area_value: "Уточненная площадь (кв. м)",
    cad_cost: "Кадастровая стоимость (руб.)",
  };

  const getFilteredProperties = (properties) =>
    Object.keys(viewFields).filter((field) =>
      Object.keys(properties)?.some((property) => field === property)
    );

  const { restrictedZones, parcels } = getFilteredLists(list);

  const renderRestrictedZones = () =>
    restrictedZones.map(
      (item, index) => item && <Value key={index}>{item}</Value>
    );

  if (!list.length) return null;

  return (
    <>
      {parcels?.map(({ properties }, index) => {
        const parcelsProperties = getFilteredProperties(properties);

        return (
          <DataList key={index} data-testid={parcelsId}>
            {parcelsProperties?.map((key, i) => {
              const label = viewFields[key];
              const value = properties[key];

              return (
                <Item
                  key={`${index}-${i}`}
                  label={label}
                  value={value}
                  specialLabel={viewFields.category_type}
                />
              );
            })}
          </DataList>
        );
      })}

      {restrictedZones.length > 0 && (
        <AdditionalData
          data-testid={restrictedZonesId}
          isMultipleParcels={parcels.length > 1}
        >
          <strong>Нахождение в ЗОУИТ: </strong>

          {renderRestrictedZones()}
        </AdditionalData>
      )}
    </>
  );
};

export default List;

import styled, { css } from "styled-components";

export const ActionsWrapper = styled.section(
  ({ theme: { colors } }) => css`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    padding-top: 10px;
    border-top: 1px solid ${colors.border};
  `
);

import { useRef } from "react";

import { useLayersContext } from "context/LayersContext";
import { addMultipleLayers, toggleCurrentLayer } from "services/mapService";

import { Checkbox } from "shared/ui/formElements";

const LayersList = ({
  layers,
  currentGroupId,
  isAnotherGroupSelected,
  groupCheckedLayers,
  checkedDiagnostic,
  removePreviouslySelectedLayers,
  saveCheckedLayers,
}) => {
  const { ngwMap, setLoading } = useLayersContext();
  const firstItemId = useRef(Date.now());
  const isAllLayersChecked = groupCheckedLayers.length === layers.length;

  const getUniqId = (id) => `${currentGroupId}_${id}`;

  const selectAllGroupLayers = () => {
    setLoading(true);
    // Select all checkboxes and save new params
    saveCheckedLayers(layers, checkedDiagnostic?.id || 0);

    addMultipleLayers({
      ngwMap,
      layers,
      diagnostic: checkedDiagnostic,
      callback: () => setLoading(false),
    });
  };

  const onChangeSelectAll = () => {
    if (isAllLayersChecked) {
      removePreviouslySelectedLayers();
      saveCheckedLayers([], checkedDiagnostic?.id || 0);
    } else {
      if (isAnotherGroupSelected) removePreviouslySelectedLayers();
      selectAllGroupLayers();
    }
  };

  const onChange = ({ target }) => {
    const { value, checked } = target;
    const currentLayer = layers.find(({ id }) => +id === +value);
    const updatedCheckedLayers = checked
      ? [...groupCheckedLayers, currentLayer]
      : groupCheckedLayers.filter(({ id }) => +id !== +value);

    if (isAnotherGroupSelected) {
      removePreviouslySelectedLayers();
    }

    // Check all checkboxes and save new params
    saveCheckedLayers(updatedCheckedLayers, checkedDiagnostic?.id || 0);

    // Toggle current layer
    setLoading(true);
    toggleCurrentLayer({
      ngwMap,
      changedLayer: currentLayer,
      diagnostic: checkedDiagnostic,
      callback: () => setLoading(false),
    });
  };

  return (
    <>
      <Checkbox
        id={firstItemId}
        name="Выбрать все"
        value={firstItemId}
        onChange={onChangeSelectAll}
        checked={isAllLayersChecked}
        data-testid="additional-layer-checkbox"
      >
        Выбрать все
      </Checkbox>

      {layers.map(({ id, name }) => {
        const uniqId = getUniqId(id);

        return (
          <Checkbox
            key={uniqId}
            id={uniqId}
            name={name}
            value={id}
            onChange={onChange}
            checked={groupCheckedLayers.some((layer) => layer.id === id)}
            data-testid="layer-checkbox"
          >
            {name}
          </Checkbox>
        );
      })}
    </>
  );
};

export default LayersList;

import { valhallaServiceHost } from "services/httpService";
import { DISTANCE_POSTFIX } from "shared/constants/layerIdPostfixes";

export const fetchDistanceGeoJson = async ({ id, lat, lon, distance }) => {
  const { data } = await valhallaServiceHost.get(
    `/isochrone?id=${id}&json={"locations":[{"lat":${lat},"lon":${lon}}],"costing":"auto","contours":[{"distance":${distance}}], "denoise":1}`
  );

  return data;
};

export const fetchRoute = async ({ fromCoordinates, toCoordinates }) => {
  const { data } = await valhallaServiceHost.get(
    `/route?json={"locations":[{"lat":${fromCoordinates.lat},"lon":${fromCoordinates.lon}},{"lat":${toCoordinates.lat},"lon":${toCoordinates.lon}}],"costing":"auto","costing_options":{"auto":{"country_crossing_penalty":2000.0}},"units":"kilometers","id":"route1"}`
  );

  return data;
};

export const getDistanceGeoJsonByPosition = async ({
  lat,
  lon,
  distances = [],
}) => {
  if (!lat || !lon) return false;

  const allData = await Promise.all(
    distances.map(async (distance) => {
      const data = await fetchDistanceGeoJson({
        id: `${distance}_${DISTANCE_POSTFIX}`,
        lat,
        lon,
        distance,
      });
      return data;
    })
  );

  return allData;
};

import styled, { css } from "styled-components";

export const MapPanelWrapper = styled.div(
  ({ theme: { down, breakpoints } }) => css`
    display: flex;
    align-items: flex-end;
    gap: 0.94rem;
    width: 100%;

    & > div:first-child {
      width: 40vw;
      max-width: 30rem;

      ${down(breakpoints.sm)} {
        width: 100%;
      }
    }
  `
);

import ToggleList from "shared/ui/ToggleList";
import Legend from "../Legend";
import { Wrapper, ListTitle, toggleListStyles } from "./styled";

const GroupList = ({ list }) => (
  <Wrapper>
    {list?.map(({ id: itemId, list_name, layers_data }) => (
      <section key={itemId}>
        {list_name && <ListTitle>{list_name}</ListTitle>}

        {layers_data.map(({ legend, layer_name, vector_type }, index) => (
          <ToggleList
            key={index}
            data={<Legend vectorType={vector_type} list={legend} />}
            buttonText={layer_name}
            customStyles={toggleListStyles}
            data-testid="group-toggle-list"
          />
        ))}
      </section>
    ))}
  </Wrapper>
);

export default GroupList;

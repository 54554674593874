import Portal from "shared/ui/Portal";
import { CloseButton } from "shared/ui/buttons";
import { Wrapper } from "./styled";

const Popup = ({ close, children, position = {} }) => (
  <Portal wrapperId="popup-portal">
    <Wrapper position={position}>
      <CloseButton isSmall onClick={close} />

      {children}
    </Wrapper>
  </Portal>
);

export default Popup;

export const { searchParams } = new URL(document.location);

export const getParamsFromUrl = (params = []) =>
  params.reduce(
    (result, param) => ({ ...result, [param]: searchParams.get(param) }),
    {}
  );

export const addParamsToUrl = (params = []) => {
  params.forEach(({ name, value }) => searchParams.set(name, value));
};

export const removeParamsFromUrl = (params = []) => {
  params.forEach((param) => searchParams.delete(param));
};

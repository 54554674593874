import { MapControl } from "@nextgis/react-ngw-map";

import usePosition from "shared/hooks/usePosition";
import { useNotificationContext } from "context/NotificationContext";

import { Button } from "shared/ui/buttons";
import { ReactComponent as NavigationIcon } from "shared/icons/navigation.svg";

const CurrentLocationButton = ({ ngwMap }) => {
  const { longitude, latitude } = usePosition();
  const { addNotification } = useNotificationContext();

  const goToCurrentLocation = () => {
    if (longitude && latitude) {
      ngwMap?.setView({
        center: [longitude, latitude],
        zoom: 10,
      });
    } else {
      addNotification({
        content: `Местоположение не найдено. Пожалуйста, убедитесь, что вы разрешили доступ к геоданным и используете безопасный протокол передачи данных (https)`,
        type: "warning",
        isCloseable: true,
      });
    }
  };

  return (
    <MapControl position="bottom-right">
      <Button isIconButton isWhite withShadow onClick={goToCurrentLocation}>
        <NavigationIcon width="1rem" height="1rem" title="Найти меня" />
      </Button>
    </MapControl>
  );
};

export default CurrentLocationButton;

import { MapControl } from "@nextgis/react-ngw-map";

import useIsZoomSmall from "shared/hooks/useIsZoomSmall";
import { ZOOM_FOR_NAVIGATION_BUTTON } from "shared/constants/mapConstants";
import { Button } from "shared/ui/buttons";
import { customButtonStyles, Popup, Wrapper } from "./styled";

const MapHelpButton = ({ ngwMap }) => {
  const isZoomSmall = useIsZoomSmall(ngwMap, ZOOM_FOR_NAVIGATION_BUTTON);

  if (!isZoomSmall) return null;

  return (
    <MapControl position="bottom-right">
      <Wrapper>
        <Button isIconButton isWhite customStyles={customButtonStyles}>
          ?
        </Button>
        <Popup>
          Функционал построения маршрутов и запроса сведений из ЕГРН доступен
          при увеличении масштаба карты
        </Popup>
      </Wrapper>
    </MapControl>
  );
};

export default MapHelpButton;

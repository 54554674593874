const parseJSON = (str) => {
  try {
    const obj = JSON.parse(str);
    let res = "";
    Object.keys(obj).forEach((key) => {
      res += `${key}: ${obj[key]}\n`;
    });
    return res;
  } catch (e) {
    return false;
  }
};

const getFilteredProperties = ({ featureLayerFields, event, viewFields }) => {
  const filteredLayerFields = featureLayerFields.filter(({ keyname }) =>
    viewFields.some((value) => keyname.toLowerCase() === value.toLowerCase())
  );

  const filteredFields = filteredLayerFields.reduce(
    (result, { id, grid_visibility, keyname, display_name }) => {
      const value = event.feature.properties[keyname];

      if (grid_visibility) {
        const name = display_name;

        result.push({ id, name, value: parseJSON(value) || value || "-" });
      }

      return result;
    },
    []
  );

  return filteredFields;
};

export default getFilteredProperties;

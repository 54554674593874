import Keycloak from "keycloak-js";

const clientId = process.env.REACT_APP_KEYCLOAK_CLIENT_ID;

export const kc = new Keycloak({
  url: process.env.REACT_APP_KEYCLOAK_AUTH_URL,
  realm: process.env.REACT_APP_KEYCLOAK_REALM,
  clientId,
});

export const initAuth = async (onAuthCallback) => {
  await kc
    .init({ onLoad: "check-sso" })
    .then((authenticated) => {
      if (!authenticated) {
        console.log("Пользователь не аутентифицирован!");
      }
      if (onAuthCallback) onAuthCallback();
    })
    .catch(console.error);
};

export const signIn = kc?.login;

export const signOut = kc?.logout;

export const getToken = () => kc?.token;

export const isLoggedIn = () => !!kc?.token;

export const updateToken = (successCallback = () => {}) =>
  kc?.updateToken(30).then(successCallback).catch(signIn);

export const getUserName = () => kc?.tokenParsed?.preferred_username;

export const getRoles = () => kc?.resourceAccess?.[clientId]?.roles;

export const hasRole = (role = "") => kc?.hasResourceRole(role);

export const hasRoleFromList = (roles = []) =>
  roles.some((role) => kc?.hasResourceRole(role));

import styled, { css } from "styled-components";

export const LoaderWrapper = styled.div(
  ({ theme: { zIndex }, position }) => css`
    position: ${position};
    ${position !== "static" &&
    css`
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: ${zIndex.max};
    `}
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgb(255 255 255 / 90%);
  `
);

export const LoaderText = styled.div(
  ({ theme: { colors } }) => css`
    margin-left: 0.94rem;
    color: ${colors.green};
    text-transform: uppercase;
    font-size: inherit;
    font-weight: bold;
  `
);

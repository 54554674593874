const moveMapToPoint = ({ ngwMap, coords = [], zoom }) => {
  if (ngwMap && coords?.length === 2) {
    ngwMap.mapAdapter.map.flyTo({
      center: coords,
      essential: true,
      zoom,
    });
  }
};

export default moveMapToPoint;

import { TooltipWrapper } from "../styled";

const ChartTooltip = (props) => {
  const { active, payload } = props;

  if (active && payload && payload.length) {
    return (
      <TooltipWrapper>
        <span className="label">{payload[0].payload.label}</span>
      </TooltipWrapper>
    );
  }

  return null;
};

export default ChartTooltip;

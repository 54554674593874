import {
  CheckboxWrapper,
  NativeCheckbox,
  CustomCheckbox,
  Label,
} from "./styled";

const Checkbox = ({
  id,
  name,
  value,
  checked,
  children,
  onChange,
  customStyles,
  ...restProps
}) => (
  <CheckboxWrapper customStyles={customStyles} {...restProps}>
    <NativeCheckbox
      id={id}
      type="checkbox"
      name={name}
      value={value}
      checked={checked}
      onChange={onChange}
    />

    <CustomCheckbox checked={checked} />
    <Label htmlFor={id}>{children}</Label>
  </CheckboxWrapper>
);
export default Checkbox;

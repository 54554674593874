import { createGlobalStyle } from "styled-components";
import { ScrollBarStyles } from "./scrollBarStyles";
import { FontStyles } from "./fontStyles";

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
  }

  :root {
    --app-height: 100%;
  }

  html,
  body {
      padding: 0;
      margin: 0;
      overflow: hidden;
      width: 100%;
      min-height: 100vh;
      min-height: var(--app-height);
      height: auto;
  }

  body {
    color: ${({ theme }) => theme.colors.gray};
    font-family: "Rubik", sans-serif;
    font-size: 16px;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  article,
  aside,
  details,
  figcaption,
  figure,
  footer,
  header,
  hgroup,
  menu,
  nav,
  section {
    display: block;
  }

  a,
  button {
    cursor: pointer;
  }

  a {
    transition: all 0.25s ease-in-out 0s;
    color: ${({ theme }) => theme.colors.green};

    &:hover {
      text-decoration: none;
    }
  }

  ul,
  ol {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .mapboxgl-map, 
  .maplibregl-map {
    font-family: "Rubik", sans-serif;
    font-size: 16px;
  }

  ${FontStyles};
  ${ScrollBarStyles};
`;

export default GlobalStyle;

import styled, { css } from "styled-components";

export const Content = styled.div`
  margin: 0;
  text-align: left;
`;

export const ListSection = styled.section(
  ({ theme: { colors } }) => css`
    position: relative;
  `
);

const getFilteredLayersData = ({ layersData, string }) => {
  const filteredLayersData = layersData?.reduce((result, layersItem) => {
    const { legend } = layersItem;
    const filteredLegend = legend?.filter(({ label }) =>
      label?.toLowerCase().includes(string)
    );

    if (filteredLegend.length > 0) {
      result.push({ ...layersItem, legend: filteredLegend });
    }
    return result;
  }, []);

  return filteredLayersData;
};

const getFilteredListData = ({ list, string }) => {
  const filteredList = list?.reduce((acc, listItem) => {
    const { layers_data: layersData } = listItem;

    const filteredLayersData = getFilteredLayersData({
      layersData,
      string,
    });

    if (filteredLayersData.length > 0) {
      acc.push({ ...listItem, layers_data: filteredLayersData });
    }
    return acc;
  }, []);

  return filteredList;
};

const filterLegendDataByString = ({ data, string }) => {
  const stringInLowerCase = string?.trim().toLowerCase();

  if (stringInLowerCase.length === 0) return data;

  return data?.reduce((finalData, item) => {
    const { list } = item;

    const filteredList = getFilteredListData({
      list,
      string: stringInLowerCase,
    });

    if (filteredList.length > 0) {
      finalData.push({ ...item, list: filteredList });
    }
    return finalData;
  }, []);
};

export default filterLegendDataByString;

import { useNotificationContext } from "context/NotificationContext";
import Notifier from "shared/ui/Notifier";

const Notifications = () => {
  const { notificationList, removeNotification } = useNotificationContext();

  if (!notificationList.length) return null;

  return <Notifier list={notificationList} removeItem={removeNotification} />;
};

export default Notifications;

import { useCallback, useEffect, useState } from "react";
import { getGroups } from "services/api";

const useGetPreparedData = (isUserLoggedIn) => {
  const [businessGroups, setBusinessGroups] = useState([]);
  const [infoLayers, setInfoLayers] = useState([]);
  const [defaultLayersData, setDefaultLayersData] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const getAllLayers = useCallback(async () => {
    try {
      if (isUserLoggedIn) {
        const { business_objects, territory_data, default_layers_data } =
          await getGroups();

        setDefaultLayersData(default_layers_data);
        setInfoLayers(territory_data);
        setBusinessGroups(business_objects);
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      setError(err);
    }
  }, [isUserLoggedIn]);

  useEffect(() => {
    getAllLayers();
  }, [getAllLayers]);

  return {
    defaultLayersData,
    businessGroups,
    infoLayers,
    loading,
    error,
  };
};

export default useGetPreparedData;

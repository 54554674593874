import styled from "styled-components";

export const ListItem = styled.li`
  display: flex;
  flex-direction: column;
  margin-bottom: 0.63rem;
`;

export const Value = styled.span`
  display: flex;
  flex-direction: column;

  & + span {
    margin-top: 0.63rem;
  }
`;

import ReactDOM from "react-dom";

import PointPopup from "components/PointPopup";
import getFilteredProperties from "./getFilteredProperties";

const generatePopupOptions = ({ id, resource, viewFields, isInfoLayer }) => ({
  popupOnSelect: true,
  popupOptions: {
    createPopupContent: (event) => {
      const {
        feature_layer: { fields },
      } = resource;

      const filteredFields = getFilteredProperties({
        featureLayerFields: fields,
        event,
        viewFields,
      });

      const div = document.createElement("div");

      ReactDOM.render(
        <PointPopup
          layerId={id}
          currentObject={event.feature}
          filteredFields={filteredFields}
          isInfoLayerPoint={isInfoLayer}
        />,
        div
      );

      return div;
    },
  },
});

export default generatePopupOptions;

import { useState, useEffect } from "react";

import { useLayersContext } from "context/LayersContext";
import { toggleCurrentLayer } from "services/mapService";
import { addLayersParamsToUrl } from "services/layersService";

import { Checkbox } from "shared/ui/formElements";
import { SectionWrapper } from "./styled";

const AdditionalLayersGroup = ({ layers }) => {
  const { ngwMap, setLoading, checkedInfoLayers, setCheckedInfoLayers } =
    useLayersContext();
  const [groupCheckedLayers, setGroupCheckedLayers] =
    useState(checkedInfoLayers);

  useEffect(() => {
    setGroupCheckedLayers(checkedInfoLayers);
  }, [checkedInfoLayers]);

  const getUniqId = (id) => `info_layer_${id}`;

  const onChange = ({ target }) => {
    const { value, checked } = target;
    const currentLayer = layers.find(({ id }) => +id === +value);
    const updatedCheckedLayers = checked
      ? [...groupCheckedLayers, currentLayer]
      : groupCheckedLayers.filter(({ id }) => +id !== +value);

    setLoading(true);
    toggleCurrentLayer({
      ngwMap,
      changedLayer: currentLayer,
      callback: () => setLoading(false),
    });
    setCheckedInfoLayers(updatedCheckedLayers);
    addLayersParamsToUrl({ additionalLayers: updatedCheckedLayers });
  };

  return (
    <SectionWrapper>
      {layers.map(({ id, name }) => {
        const uniqId = getUniqId(id);
        return (
          <Checkbox
            key={uniqId}
            id={uniqId}
            name={name}
            value={id}
            onChange={onChange}
            checked={groupCheckedLayers.some((layer) => layer.id === id)}
            data-testid="layer-checkbox"
          >
            {name}
          </Checkbox>
        );
      })}
    </SectionWrapper>
  );
};

export default AdditionalLayersGroup;

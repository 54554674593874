import styled, { css } from "styled-components";

const minusIcon =
  "data:image/svg+xml,%3C%3Fxml version='1.0' encoding='iso-8859-1'%3F%3E%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 52.161 52.161' style='enable-background:new 0 0 52.161 52.161;' xml:space='preserve' fill='white'%3E%3Cpath d='M52.161,26.081c0,3.246-2.63,5.875-5.875,5.875H5.875C2.63,31.956,0,29.327,0,26.081l0,0c0-3.245,2.63-5.875,5.875-5.875 h40.411C49.531,20.206,52.161,22.835,52.161,26.081L52.161,26.081z' fill='currentColor' /%3E%3C/svg%3E%0A";
const plusIcon =
  "data:image/svg+xml,%3C%3Fxml version='1.0' encoding='iso-8859-1'%3F%3E%3Csvg version='1.1' id='Capa_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 60.364 60.364' style='enable-background:new 0 0 60.364 60.364;' xml:space='preserve' fill='white'%3E%3Cpath d='M54.454,23.18l-18.609-0.002L35.844,5.91C35.845,2.646,33.198,0,29.934,0c-3.263,0-5.909,2.646-5.909,5.91v17.269 L5.91,23.178C2.646,23.179,0,25.825,0,29.088c0.002,3.264,2.646,5.909,5.91,5.909h18.115v19.457c0,3.267,2.646,5.91,5.91,5.91 c3.264,0,5.909-2.646,5.91-5.908V34.997h18.611c3.262,0,5.908-2.645,5.908-5.907C60.367,25.824,57.718,23.178,54.454,23.18z' fill='currentColor' /%3E%3C/svg%3E%0A";

const MapWrapper = styled.div(
  ({ theme: { colors, zIndex, mapBoxShadow, borderRadius } }) => css`
    .mapboxgl-ctrl,
    .maplibregl-ctrl {
      margin: 12px 16px;
    }

    .maplibregl-ctrl-top-left,
    .mapboxgl-ctrl-top-left {
      z-index: ${zIndex.aboveContent};
    }

    .maplibregl-ctrl-bottom-right,
    .mapboxgl-ctrl-bottom-right {
      top: 0;
      margin: auto;
      height: fit-content;
    }

    .maplibregl-ctrl-top-right,
    .mapboxgl-ctrl-top-right {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      z-index: ${zIndex.aboveContent};

      .maplibregl-ctrl:first-child {
        order: 2;
      }

      .maplibregl-ctrl:last-child {
        order: 1;
        z-index: 1;
      }
    }

    .mapboxgl-ctrl-group,
    .maplibregl-ctrl-group {
      overflow: hidden;
      border-radius: 24px;
      box-shadow: ${mapBoxShadow};
      background-color: transparent;
    }

    .maplibregl-ctrl-zoom-in span,
    .mapboxgl-ctrl-zoom-in span {
      background-image: url("${plusIcon}") !important;
      background-size: 50%;
    }

    .maplibregl-ctrl-zoom-out span,
    .mapboxgl-ctrl-zoom-out span {
      background-image: url("${minusIcon}") !important;
      background-size: 50%;
    }

    .maplibregl-ctrl-zoom-in,
    .mapboxgl-ctrl-zoom-in,
    .maplibregl-ctrl-zoom-out,
    .mapboxgl-ctrl-zoom-out {
      width: 40px;
      height: 40px;
      padding: 8px;
      background-color: white;
      transition: all 0.4s;

      &:not(:disabled):hover,
      &:not(:disabled):focus {
        background-color: ${colors.lightestGray};
      }
    }

    .mapboxgl-ctrl button:not(:disabled):hover,
    .maplibregl-ctrl button:not(:disabled):hover {
      background-color: ${colors.lightestGray};
    }

    .mapboxgl-ctrl-group button + button,
    .maplibregl-ctrl-group button + button {
      border: 0;
    }

    .maplibregl-popup,
    .mapboxgl-popup {
      width: 100%;
      max-width: 20rem !important;
      z-index: ${zIndex.aboveContent};
    }

    .mapboxgl-popup-close-button,
    .maplibregl-popup-close-button {
      top: 15px;
      right: 15px;
      width: 15px;
      height: 15px;
      padding: 0;
      font-size: 25px;
      line-height: 0;
      color: ${colors.gray};
    }

    .mapboxgl-popup-close-button:hover,
    .maplibregl-popup-close-button:hover {
      background-color: transparent;
      color: ${colors.black};
    }

    .maplibregl-popup-content,
    .mapboxgl-popup-content {
      padding: 15px;
      border-radius: ${borderRadius};
      box-shadow: ${mapBoxShadow};
    }

    .maplibregl-ctrl-attrib {
      display: none;
    }
  `
);

export default MapWrapper;

import { useState } from "react";

import { useLayersContext } from "context/LayersContext";
import { removeVisibleObjectLayers } from "services/mapService";
import { Button, CloseButton } from "shared/ui/buttons";
import { ReactComponent as LayersIcon } from "shared/icons/layers.svg";
import { ReactComponent as LayersFillIcon } from "shared/icons/layers-fill.svg";
import { ReactComponent as TrafficLightIcon } from "shared/icons/traffic-light.svg";
import LayersGroup from "./LayersGroup";
import AdditionalLayersGroup from "./AdditionalLayersGroup";
import {
  Wrapper,
  PanelWrapper,
  SectionsWrapper,
  Section,
  SectionTitle,
  ButtonGroup,
  ButtonBadge,
} from "./styled";
import DiagnosticsGroup from "./DiagnosticsGroup";

const LayersPanel = ({ groups, infoLayers }) => {
  const [activePanel, setActivePanel] = useState("");

  const { ngwMap, checkedLayers, checkedInfoLayers, checkedDiagnostic } =
    useLayersContext();

  const toggleActivePanel = (panel) => {
    if (activePanel === panel) setActivePanel("");
    else setActivePanel(panel);
  };

  // Delete old checked layers here, because the group dropdowns may be closed
  const removePreviouslySelectedLayers = () => {
    removeVisibleObjectLayers({ ngwMap, layers: checkedLayers });
  };

  return (
    <Wrapper>
      <ButtonGroup>
        <Button
          isIconButton
          isWhite
          isActive={activePanel === "layers"}
          title="Объекты РСС"
          onClick={() => toggleActivePanel("layers")}
        >
          <LayersIcon width="1rem" height="1rem" />
          {checkedLayers.length > 0 && (
            <ButtonBadge>{checkedLayers.length}</ButtonBadge>
          )}
        </Button>

        <Button
          isIconButton
          isWhite
          isActive={activePanel === "diagnostics"}
          title="Диагностики состояний"
          onClick={() => toggleActivePanel("diagnostics")}
        >
          <TrafficLightIcon width="1rem" height="1rem" />
          {checkedDiagnostic && <ButtonBadge />}
        </Button>
      </ButtonGroup>

      <Button
        isIconButton
        isWhite
        isActive={activePanel === "external"}
        title="Внешняя инфраструктура"
        onClick={() => toggleActivePanel("external")}
      >
        <LayersFillIcon width="1rem" height="1rem" />

        {checkedInfoLayers.length > 0 && (
          <ButtonBadge>{checkedInfoLayers.length}</ButtonBadge>
        )}
      </Button>

      {activePanel === "layers" && (
        <PanelWrapper>
          <CloseButton isSmall onClick={() => setActivePanel("")} />

          <SectionsWrapper>
            {groups.length > 0 && (
              <Section>
                <SectionTitle>Объекты РСС</SectionTitle>
                <LayersGroup
                  layers={groups[0].layers}
                  diagnostics={groups[0].diagnostics}
                  currentGroupId={groups[0].id}
                  removePreviouslySelectedLayers={
                    removePreviouslySelectedLayers
                  }
                />
              </Section>
            )}
          </SectionsWrapper>
        </PanelWrapper>
      )}

      {activePanel === "diagnostics" && (
        <PanelWrapper>
          <CloseButton isSmall onClick={() => setActivePanel("")} />

          <SectionsWrapper>
            {groups.length > 0 && (
              <Section>
                <SectionTitle>Диагностики состояний</SectionTitle>
                <DiagnosticsGroup
                  layers={groups[0].layers}
                  diagnostics={groups[0].diagnostics}
                  currentGroupId={groups[0].id}
                  removePreviouslySelectedLayers={
                    removePreviouslySelectedLayers
                  }
                />
              </Section>
            )}
          </SectionsWrapper>
        </PanelWrapper>
      )}

      {activePanel === "external" && (
        <PanelWrapper>
          <CloseButton isSmall onClick={() => setActivePanel("")} />

          <SectionsWrapper>
            {infoLayers.length > 0 && (
              <Section>
                <SectionTitle>Внешняя инфраструктура</SectionTitle>
                <AdditionalLayersGroup layers={infoLayers} />
              </Section>
            )}
          </SectionsWrapper>
        </PanelWrapper>
      )}
    </Wrapper>
  );
};

export default LayersPanel;

/* eslint-disable prefer-destructuring */
import { useState, useCallback, useEffect, createRef } from "react";
import { useForm } from "react-hook-form";

import { useNotificationContext } from "context/NotificationContext";
import Loader from "shared/ui/loaders/Loader";
import { getRequestTypes, sendRequest, uploadFile } from "services/api";
import { ReactComponent as CloseIcon } from "shared/icons/close.svg";
import {
  FormWrapper,
  Wrapper,
  Button,
  Error,
  InputGroup,
  FormElement,
  Completed,
  FileBlock,
  FileList,
  FileListItem,
  FileInput,
  FileButton,
  RemoveFileButton,
} from "./styled";

const Feedback = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [requestTypes, setRequestTypes] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const [loading, setLoading] = useState(true);
  const [completed, setCompleted] = useState(false);
  const { addNotification } = useNotificationContext();
  const fileInput = createRef();
  const [allFiles, setAllFiles] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);

  const fetchRequestTypes = useCallback(async () => {
    try {
      const data = await getRequestTypes();
      if (data) setRequestTypes(data.data.items);
      setLoading(false);
    } catch (error) {
      addNotification({ content: error, type: "error" });
      setLoading(false);
    }
  }, [addNotification]);

  useEffect(() => {
    fetchRequestTypes();
  }, [fetchRequestTypes]);

  const onSubmit = async (data) => {
    setSubmitting(true);
    const body = { ...data, address: "" };
    const splittedName = body.name.split(" ");
    body.last_name = splittedName[0];
    body.first_name = splittedName[1];
    body.patronymic = splittedName.slice(2).join(" ");
    body.text = `Обращение из Интерактивной карты: ${body.text}`;
    delete body.name;
    body.attachment_ids = uploadedFiles.map((item) => item.id);
    try {
      await sendRequest(body);
      setCompleted(true);
      setSubmitting(false);
    } catch (error) {
      setSubmitting(false);
    }
  };

  const addFiles = (event) => {
    event.preventDefault();
    fileInput.current.click();
  };

  const removeUploadedFile = (index) => {
    setUploadedFiles((prev) => prev.filter((_, ind) => ind !== index));
  };

  const handleFileEvent = (e) => {
    const newFiles = Array.from(e.target.files);
    setAllFiles((prevArr) => [...prevArr, ...newFiles]);
  };

  useEffect(() => {
    const onUploadFile = async () => {
      const lastIndex = allFiles.length - 1;
      const data = new FormData();
      data.append("file", allFiles[lastIndex]);
      try {
        const res = await uploadFile(data);
        setUploadedFiles((prevArr) => [
          ...prevArr,
          {
            url: URL.createObjectURL(allFiles[lastIndex]),
            id: res.data.id,
          },
        ]);
      } catch (error) {
        // setFiles(arr => arr.filter((_, i) => i !== lastIndex))
      }
    };
    if (allFiles.length > 0) {
      allFiles.forEach(() => {
        onUploadFile();
      });
      setAllFiles([]);
    }
  }, [allFiles]);

  return (
    <Wrapper>
      {loading && <Loader text="Загрузка данных" position="absolute" />}
      {!completed && (
        <FormWrapper onSubmit={handleSubmit(onSubmit)}>
          <InputGroup>
            <FormElement
              as="select"
              {...register("type_id", { required: true })}
              error={errors.type_id}
            >
              <option value="">Выберите тип обращения</option>
              {requestTypes?.map(({ id, title }) => (
                <option value={id} key={id}>
                  {title}
                </option>
              ))}
            </FormElement>
            {errors.type_id && <Error>Обязательное поле</Error>}
          </InputGroup>
          <InputGroup>
            <FormElement
              as="input"
              placeholder="ФИО"
              {...register("name", {
                required: true,
                pattern: /^\S+\s\S+\s\S+.*/i,
              })}
              error={errors.name}
            />
            {errors.name && <Error>Обязательное поле</Error>}
          </InputGroup>
          <InputGroup>
            <FormElement
              as="input"
              placeholder="Номер телефона"
              {...register("phone", { required: true })}
              error={errors.phone}
            />
            {errors.phone && <Error>Обязательное поле</Error>}
          </InputGroup>
          <InputGroup>
            <FormElement
              as="input"
              placeholder="E-mail"
              {...register("email", { required: true })}
              error={errors.email}
            />
            {errors.email && <Error>Обязательное поле</Error>}
          </InputGroup>
          <InputGroup>
            <FormElement
              as="textarea"
              placeholder="Введите текст обращения"
              {...register("text", { required: true })}
              error={errors.text}
            />
            {errors.text && <Error>Обязательное поле</Error>}
          </InputGroup>

          <FileButton onClick={addFiles}>Добавить фото</FileButton>
          <FileBlock>
            {uploadedFiles?.length > 0 && (
              <FileList>
                {uploadedFiles?.map((file, index) => (
                  <FileListItem key={index}>
                    <img src={file.url} alt="загруженное изображение" />
                    <RemoveFileButton
                      type="button"
                      onClick={() => removeUploadedFile(index)}
                    >
                      <CloseIcon />
                    </RemoveFileButton>
                  </FileListItem>
                ))}
              </FileList>
            )}
            <FileInput
              ref={fileInput}
              type="file"
              multiple
              onChange={handleFileEvent}
            />
          </FileBlock>

          <Button as="button" type="submit" disabled={submitting}>
            Отправить
          </Button>
        </FormWrapper>
      )}
      {completed && <Completed>Обращение успешно отправлено!</Completed>}
    </Wrapper>
  );
};

export default Feedback;

import typesOfLandPlots from "shared/constants/typesOfLandPlots";
import { ListItem, Value } from "./styled";

const Item = ({ label, value, specialLabel }) => {
  // This is necessary because the value of this property can be a number or a string
  const isCategoryNumber = label === specialLabel && !Number.isNaN(value);
  const defaultValue = isCategoryNumber ? typesOfLandPlots[value] : value;

  if (!label) return null;

  return (
    <ListItem>
      <strong>{label}: </strong>
      <Value>{defaultValue || "-"}</Value>
    </ListItem>
  );
};

export default Item;

import { useCallback, useRef, useEffect, useState } from "react";
import {
  Message,
  Content,
  MessageIcon,
  CloseButton,
  CloseIcon,
} from "./styled";

const Notification = (props) => {
  const {
    id,
    content,
    onClose,
    isCloseable,
    type = "success",
    delay = 10000,
    removeFromList,
    customStyles,
  } = props;
  const [isVisible, setIsVisible] = useState(content);
  const [className, setClassName] = useState(null);
  const animationTimer = useRef(null);
  let icon;

  const isWarning = type === "warning";
  const isError = type === "error";
  const wrapperId = `notification-${id}`;

  const closeNotification = useCallback(() => {
    if (animationTimer.current) clearTimeout(animationTimer.current);

    setClassName("closing");
    animationTimer.current = setTimeout(() => {
      setIsVisible(false);
      if (onClose) onClose();
      if (removeFromList) removeFromList(id);
    }, 400);
  }, [onClose, id, removeFromList]);

  useEffect(() => {
    let notifierTimer;

    if (!isCloseable) {
      notifierTimer = setTimeout(() => closeNotification(), delay);
    }

    return () => {
      if (animationTimer.current) clearTimeout(animationTimer.current);
      if (notifierTimer) clearTimeout(notifierTimer);
    };
  }, [isCloseable, closeNotification, delay]);

  if (!isVisible) return null;

  return (
    <Message
      id={wrapperId}
      className={className}
      customStyles={customStyles}
      isWarning={isWarning}
      isError={isError}
    >
      {content && (
        <Content>
          {icon && <MessageIcon>{icon}</MessageIcon>}
          <div>{content}</div>
        </Content>
      )}

      {isCloseable && (
        <CloseButton
          type="button"
          title="Закрыть"
          aria-label="Закрыть сообщение"
          onClick={closeNotification}
        >
          <CloseIcon />
        </CloseButton>
      )}
    </Message>
  );
};

export default Notification;

const getVisibleLayerIdsByName = ({ ngwMap, subString }) => {
  const allLayerIds = ngwMap.getLayers();
  const visibleLayerIds = allLayerIds?.reduce((result, item) => {
    if (item.includes(subString)) {
      result.push(item);
    }
    return result;
  }, []);

  return visibleLayerIds;
};

export default getVisibleLayerIdsByName;

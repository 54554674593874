import styled, { css } from "styled-components";

export const ImageWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0;
  .image-gallery-content .image-gallery-image {
    height: calc(95vh - 2.2rem);
    max-height: inherit;
  }

  .image-gallery-content.fullscreen .image-gallery-image {
    height: 100vh;
  }

  .image-gallery-icon:hover {
    color: #53b178;
  }
`;

export const Image = styled.img`
  max-width: 100%;
  max-height: 100%;
  margin: auto;
`;

export const EmptyText = styled.span`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  height: fit-content;
  width: fit-content;
`;

export const customSpinnerStyles = () => css`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

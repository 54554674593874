import styled, { css } from "styled-components";

export const Overlay = styled.div(
  ({ theme: { zIndex } }) => css`
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: ${zIndex.aboveContent};
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgb(255 255 255 / 85%);
  `
);

export const Wrapper = styled.div(
  ({ theme: { colors, borderRadius, mapBoxShadow } }) => css`
    position: relative;
    width: 95%;
    height: 95%;
    padding-top: 40px;
    border-radius: ${borderRadius};
    box-shadow: ${mapBoxShadow};
    background-color: ${colors.white};
    overflow: hidden;
  `
);

export const buttonCustomStyles = () => css`
  position: absolute;
  top: 0;
  right: 0;
  margin: 0.63rem 0.94rem;
  font-weight: bold;
`;

const getCompareExpression = ({ colorValues, columnName }) => {
  const result = [];

  colorValues.forEach(({ icon, values }) => {
    const [startValue, endValue] = values || [];

    /** Result:
      [
        "all",
        ["<", ["to-number", ["get", propertyName]], propertyValue[1]],
        [">=", ["to-number", ["get", propertyName]], propertyValue[0]],
      ],
      icon
    */
    if ((startValue || startValue === 0) && endValue) {
      result.push([
        "all",
        ["<", ["to-number", ["get", columnName]], +endValue],
        [">=", ["to-number", ["get", columnName]], +startValue],
      ]);
      result.push(icon?.name);
    }

    /** Result:
      [">", ["to-number", ["get", propertyName]], propertyValue[0]],
      icon
    */
    if (startValue && !endValue) {
      result.push([">=", ["to-number", ["get", columnName]], +startValue]);
      result.push(icon?.name);
    }
  });

  return result;
};

const getListExpression = ({ colorValues, columnName }) => {
  const result = [];

  /** Result (the `iconColorName` should be red/yellow/green):
    ["==", ["get", propertyName], propertyValue1],
    "icon",
    ["==", ["get", propertyName], propertyValue2],
    "icon2",
  */
  colorValues.forEach(({ icon, values }) => {
    values.forEach((value) => {
      result.push(["==", ["get", columnName], value]);
      result.push(icon?.name);
    });
  });

  return result;
};

const getIconExpressionForDiagnostics = ({ colorData, defaultIcon }) => {
  const {
    type,
    field_name: columnName,
    color_values: colorValues,
  } = colorData || {};
  const result = ["case"];

  const expressionFn =
    type === "compare" ? getCompareExpression : getListExpression;

  const expressionResult = expressionFn({
    colorValues,
    columnName,
  });

  result.push(...expressionResult);
  result.push(defaultIcon);

  return colorValues?.length > 0 ? result : defaultIcon;
};

export default getIconExpressionForDiagnostics;

import { colors as themeColors } from "shared/styles/theme";
import checkLayerGeometryType from "services/mapService/checkLayerGeometryType";
import { getColorsExpression } from "services/mapService/getExpressions";

const generateColorOptions = ({ colors, geometryType, renderProperties }) => {
  if (!colors && !geometryType && !renderProperties) return {};

  const { default_color: defaultLayerColor } = renderProperties || {};

  const defaultColor = defaultLayerColor || themeColors.layerDefaultColor;
  const paintExpression = colors
    ? getColorsExpression({ colorData: colors, defaultColor })
    : null;

  const { isLinesLayer, isPolygonsLayer } =
    checkLayerGeometryType(geometryType);

  let paintProperty = "circle-color";
  let additionalPaintProperties = {
    "circle-radius": 10,
    "circle-stroke-color": paintExpression || defaultColor,
    "circle-stroke-width": 2,
    "circle-opacity": 0.8,
  };
  const polygonOrPointType = isPolygonsLayer ? "fill" : "circle";
  const type = isLinesLayer ? "line" : polygonOrPointType;

  if (isLinesLayer) {
    paintProperty = "line-color";
    additionalPaintProperties = { "line-width": 4 };
  }
  if (isPolygonsLayer) {
    paintProperty = "fill-color";
    additionalPaintProperties = { "fill-opacity": 0.5 };
  }

  const paint = {
    [paintProperty]: paintExpression || defaultColor,
    ...additionalPaintProperties,
  };

  return {
    paint,
    selectedPaint: paint,
    nativeOptions: { type },
    nativePaint: true,
  };
};

export default generateColorOptions;

import { useEffect, useCallback } from "react";

import { useLayersContext } from "context/LayersContext";
import { addLayersParamsToUrl } from "services/layersService";
import { getParamsFromUrl } from "services/urlParamsService";

import DiagnosticsList from "../DiagnosticsList";
import { Group, SectionWrapper } from "./styled";

const DiagnosticsGroup = ({
  layers,
  diagnostics,
  currentGroupId,
  removePreviouslySelectedLayers,
}) => {
  const { groupId: selectedGroupId, diagnosticId: selectedDiagnosticId } =
    getParamsFromUrl(["groupId", "diagnosticId"]);
  const isAnotherGroupSelected = +currentGroupId !== +selectedGroupId;
  const {
    checkedLayers,
    setCheckedLayers,
    checkedDiagnostic,
    setCheckedDiagnostic,
  } = useLayersContext();

  const currentDiagnostic = diagnostics.find(
    ({ id }) => +id === +selectedDiagnosticId
  );
  const defaultDiagnostic =
    +selectedDiagnosticId && !isAnotherGroupSelected ? currentDiagnostic : null;

  const saveCheckedLayers = useCallback(
    (updatedLayers, diagnosticId) => {
      setCheckedLayers(updatedLayers);
      addLayersParamsToUrl({
        baseLayers: updatedLayers,
        groupId: currentGroupId,
        diagnosticId,
      });
    },
    [currentGroupId, setCheckedLayers]
  );

  const saveCheckedDiagnostic = useCallback(
    (updatedDiagnostic) => {
      setCheckedDiagnostic(updatedDiagnostic);
    },
    [setCheckedDiagnostic]
  );

  // Update list of the selected layers when enabling/disabling layers from another places (example: when user searches by cadastral number)
  useEffect(() => {
    setCheckedDiagnostic(defaultDiagnostic);
  }, [checkedLayers, defaultDiagnostic, setCheckedDiagnostic]);

  return (
    <Group>
      {diagnostics.length > 0 && (
        <SectionWrapper>
          <DiagnosticsList
            layers={layers}
            diagnostics={diagnostics}
            checkedDiagnostic={checkedDiagnostic}
            setCheckedDiagnostic={saveCheckedDiagnostic}
            removePreviouslySelectedLayers={removePreviouslySelectedLayers}
            saveCheckedLayers={saveCheckedLayers}
          />
        </SectionWrapper>
      )}
    </Group>
  );
};

export default DiagnosticsGroup;

import styled, { css } from "styled-components";

export const SearchWrapper = styled.div(
  ({ theme: { colors, mapBoxShadow } }) => css`
    position: relative;
    width: 100%;
    border-radius: 24px;
    box-shadow: ${mapBoxShadow};
    background-color: white;
    transition: all 0.3s ease-in-out;
    overflow: hidden;

    ${({ active }) =>
      active &&
      `
      border-color: ${colors.green};
    `}

    input {
      height: 46px;
      padding-left: 10px;
      border: none;
      border-radius: 0;
    }
  `
);

export const InputWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 20px 0 10px;
`;

export const SearchList = styled.ul(
  () => css`
    display: block;
    width: 100%;
    max-height: 12.9rem;
    overflow: auto;
    list-style: none;
  `
);

export const SearchItem = styled.li(
  ({ theme: { colors } }) => css`
    position: relative;
    display: flex;
    gap: 10px;
    justify-content: space-between;
    padding: 15px 20px;
    cursor: pointer;

    &:not(:last-child):after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 15px;
      right: 15px;
      height: 1px;
      background-color: ${colors.border};
    }

    &:hover {
      background-color: ${colors.lightestGray};
    }
  `
);

export const CustomCloseButton = () => css`
  position: relative;
  top: 0;
  right: 0;
`;

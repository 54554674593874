import { Component } from "react";
import ErrorScreen from "shared/ui/ErrorScreen";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.log({ error, errorInfo });
  }

  render() {
    const { state, props } = this;
    if (state.hasError) {
      return <ErrorScreen />;
    }

    return props.children;
  }
}

export default ErrorBoundary;

const getGroupData = ({ params, groups, defaultGroupId }) => {
  const groupIdParam = params.get("groupId");
  const currentGroupId = groupIdParam || defaultGroupId;
  const currentGroup = groups.find((group) => +group.id === +currentGroupId);

  return {
    groupIdParam,
    currentGroupId,
    currentGroup,
  };
};

export default getGroupData;

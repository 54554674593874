import { getDistanceGeoJsonByPosition } from "services/valhallaService";
import getLayerCenter from "../getLayerCenter";

const addDistanceLayers = async ({
  ngwMap,
  layerGeoJson,
  distances,
  colors = { smallDistanceColor: "gold", largeDistanceColor: "green" },
}) => {
  const [lon, lat] = getLayerCenter(layerGeoJson);
  const distanceGeoJsonArr = await getDistanceGeoJsonByPosition({
    lon,
    lat,
    distances,
  });

  distanceGeoJsonArr?.forEach(async (geoJson) => {
    const { id: geoJsonId } = geoJson || {};
    const { smallDistanceColor, largeDistanceColor } = colors;
    const defaultColor = geoJsonId.includes(distances[0])
      ? smallDistanceColor
      : largeDistanceColor;

    await ngwMap?.addGeoJsonLayer({
      id: geoJsonId,
      data: geoJson,
      order: 0,
      paint: { color: defaultColor, weight: 3 },
    });
  });
};

export default addDistanceLayers;

import {
  INFO_LAYER,
  LAYER,
  OBJECT_LAYER,
} from "shared/constants/layerIdPostfixes";

const generateLayerId = (id, isInfoLayer, service) => {
  if (!id) return false;

  let layerId = "";

  if (service) {
    layerId = isInfoLayer
      ? `${id}_${service}_${INFO_LAYER}`
      : `${id}_${service}_${LAYER}`;
  } else {
    layerId = isInfoLayer ? `${id}_${INFO_LAYER}` : `${id}_${OBJECT_LAYER}`;
  }

  return layerId;
};

export default generateLayerId;

/* eslint-disable no-param-reassign */
import axios from "axios";
import { getToken, updateToken, isLoggedIn } from "../authService";

export const apiHost = axios.create({
  baseURL: `/api/v1`,
});

export const apiTS = axios.create({
  baseURL: "https://api.gs.tatneft.ru/api/v2",
});

apiHost.interceptors.request.use((config) => {
  if (isLoggedIn()) {
    const cb = () => {
      config.headers.Authorization = `Bearer ${getToken()}`;
      return config;
    };
    return updateToken(cb);
  }
});

export const nextGisGeoServiceHost = axios.create({
  baseURL: "",
});

export const valhallaServiceHost = axios.create({
  baseURL: "",
  auth: {
    username: process.env.REACT_APP_VALHALLA_USER,
    password: process.env.REACT_APP_VALHALLA_PASSWORD,
  },
});

import { withTheme } from "styled-components";
import { ResponsiveContainer, Tooltip, AreaChart, Area } from "recharts";

import ChartTooltip from "./ChartTooltip";
import { SparkLine } from "./styled";

const TinyAreaChart = ({ data, color, theme }) => (
  <SparkLine>
    <ResponsiveContainer width="100%" height={100}>
      <AreaChart data={data} height={100}>
        <Area
          type="monotone"
          dataKey="value"
          stroke={color || theme.colors.grayAD}
          strokeWidth={2}
          fill={color || theme.colors.grayAD}
          fillOpacity={0.15}
        />
        <Tooltip content={<ChartTooltip />} />
      </AreaChart>
    </ResponsiveContainer>
  </SparkLine>
);
export default withTheme(TinyAreaChart);

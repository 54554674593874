import styled, { css } from "styled-components";
import { ReactComponent as ArrowDown } from "shared/icons/arrow-down.svg";

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
`;

export const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.94rem;
  align-self: center;
  width: 100%;
  max-width: 35rem;
  height: 100%;
  padding-top: 0.5rem;
  padding-bottom: 1.25rem;
`;

export const List = styled.aside`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const SearchInput = styled.input`
  padding: 0.3rem 0.5rem;
  font-size: 1.13rem;
  border-radius: 0.25rem;
  border: 0.06rem solid #adadad;
`;

export const listButtonStyles = () => css`
  margin-bottom: 1rem;
  text-align: left;
  text-decoration: none;
  font-size: 1rem;

  &:hover {
    text-decoration: underline;
  }
`;

export const DashboardWrapper = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

export const DashboardHeader = styled.header`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const backButtonStyles = () => css`
  margin-right: 1rem;
  margin-left: 0.94rem;
`;

export const Arrow = styled(ArrowDown)`
  width: 50%;
  transform: rotate(90deg);
`;

export const Title = styled.h1`
  margin: 1rem 0;
  font-size: 1.25rem;
`;

export const Group = styled.section`
  display: flex;
  flex-direction: column;
`;

import { useCallback, useEffect, useState } from "react";
import { MIN_ZOOM } from "../constants/mapConstants";

export default function useIsZoomSmall(ngwMap, zoomValue) {
  const [zoom, setZoom] = useState(MIN_ZOOM);
  const onZoomChange = useCallback(() => {
    setZoom(ngwMap?.getZoom());
  }, [ngwMap]);

  useEffect(() => {
    ngwMap?.emitter.on("zoom", onZoomChange);

    return () => {
      ngwMap?.emitter.on("zoom", onZoomChange);
    };
  }, [ngwMap, onZoomChange]);

  return zoom < zoomValue;
}

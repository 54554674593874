import { useState, useEffect } from "react";

import { getInfo } from "services/api";
import { useNotificationContext } from "context/NotificationContext";

import Loader from "shared/ui/loaders/Loader";
import GroupTitle from "shared/ui/GroupTitle";

import { Wrapper, GroupsWrapper, Group, Content } from "./styled";
import GroupList from "./GroupList";
import Search from "./Search";

const Help = () => {
  const [loading, setLoading] = useState(true);
  const [helpData, setHelpData] = useState([]);
  const [filteredList, setFilteredList] = useState(helpData);
  const { addNotification } = useNotificationContext();

  useEffect(() => {
    const getHelpData = async () => {
      try {
        const data = await getInfo();
        setHelpData(data);
        setLoading(false);
      } catch (error) {
        setHelpData([]);
        setLoading(false);
        addNotification({ content: error, type: "error" });
      }
    };

    getHelpData();
  }, [addNotification]);

  useEffect(() => {
    setFilteredList(helpData);
  }, [helpData]);

  return (
    <Wrapper>
      {loading ? (
        <Loader text="Загрузка данных" position="absolute" />
      ) : (
        <Content>
          <Search data={helpData} setFilteredList={setFilteredList} />

          <GroupsWrapper>
            {filteredList?.map(({ id, group_name, list }) => (
              <Group key={id}>
                {group_name && <GroupTitle>{group_name}</GroupTitle>}

                <GroupList list={list} />
              </Group>
            ))}
          </GroupsWrapper>
        </Content>
      )}
    </Wrapper>
  );
};

export default Help;

import { Button, TextButton } from "../buttons";

/**
 *
 * @param {buttonType} = "common", "link" or "icon"
 */

const DropdownButton = ({
  buttonText,
  buttonType = "common",
  icon,
  setIsVisible,
  isActive = false,
}) => {
  const ToggleButton = buttonType === "link" ? TextButton : Button;

  return (
    <ToggleButton
      isIconButton={buttonType === "icon"}
      onClick={() => setIsVisible((prev) => !prev)}
      title={icon && buttonText}
      isActive={isActive}
    >
      {icon && icon}
      {!icon && buttonText && buttonText}
    </ToggleButton>
  );
};

export default DropdownButton;

const loadImage = ({ ngwMap, iconName, iconUrl }) => {
  const mapAdapter = ngwMap?.mapAdapter.map;

  if (!mapAdapter?.hasImage(iconName)) {
    mapAdapter?.loadImage(iconUrl, (error, icon) => {
      if (!error) mapAdapter?.addImage(iconName, icon);
    });
  }
};

export default loadImage;

import styled, { css } from "styled-components";

export const DropdownWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`;

export const MenuWrapper = styled.div`
  position: fixed;
  top: -12px;
  left: -16px;

  ${({ isDropdownVisible }) =>
    isDropdownVisible &&
    `
      & > div:first-child {
        display: block;
      }

      & > ul:last-child {
        width: 320px;
      }
  `}
`;

export const DropdownOutside = styled.div`
  display: none;
  position: absolute;
  height: 100vh;
  width: 100vw;
  background: #000;
  opacity: 0.3;
`;

export const Menu = styled.ul(
  ({ theme: { colors } }) => css`
    position: relative;
    height: 100vh;
    width: 0;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    background-color: ${colors.white};
    list-style: none;
    box-shadow: 0 1px 3px rgba(60, 64, 67, 0.3),
      0 4px 8px 3px rgba(60, 64, 67, 0.15);
    transition: width 0.3s ease-in-out;

    & > div:last-child {
      margin-top: auto;
    }
  `
);

export const MenuHeader = styled.div`
  padding: 10px 22px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  button {
    position: relative;
    top: 0;
    right: 0;
  }
`;

export const MenuItem = styled.li(
  ({ theme: { colors } }) => css`
    display: flex;
    align-items: center;
    width: 100%;
    overflow: hidden;
    padding: 6px 22px;

    &:first-child {
      border-bottom: 0.06rem solid ${colors.grayAD};
      font-weight: bold;
    }

    &:not(:first-child) {
      cursor: pointer;
      transition: all 0.2s;
    }

    svg {
      min-width: 1.56rem;
      min-height: 1.56rem;
      margin-right: 0.63rem;
    }
  `
);

export const DropdownText = styled.span`
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const buttonStyles = ({ colors }) => css`
  width: 100%;
  font-size: 16px;
  line-height: 32px;
  white-space: nowrap;
  text-decoration: none;
  color: ${colors.gray};

  &:hover,
  &:focus {
    color: ${colors.green};
    background-color: none !important;
  }
`;

export const menuButtonStyles = ({ colors }) => css`
  color: ${colors.lightGray};

  &:hover,
  &:focus {
    color: #000;
    background-color: transparent !important;
  }

  svg {
    width: 100%;
    height: 100%;
  }
`;

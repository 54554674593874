import { useState, useCallback, useEffect } from "react";
import { getUserInfo } from "services/api";

const useGetUserInfo = ({ isUserLoggedIn, userInfoCallback }) => {
  const [userInfo, setUserInfo] = useState(null);
  const [error, setError] = useState(null);

  const getUserData = useCallback(async () => {
    try {
      const info = await getUserInfo();
      if (info) {
        userInfoCallback(info);
        setUserInfo(info);
      }
    } catch (err) {
      setError(err);
    }
  }, [userInfoCallback]);

  useEffect(() => {
    if (isUserLoggedIn && !userInfo) {
      getUserData();
    }
  }, [isUserLoggedIn, userInfo, getUserData]);

  return { userInfo, error };
};

export default useGetUserInfo;

import styled, { css } from "styled-components";

const StyledButton = styled.a(
  ({ customStyles, theme }) => css`
    display: inline-flex;
    align-items: center;
    margin: 0;
    padding: 0;
    border: none;
    background-color: transparent;
    text-decoration: underline;
    transition: all 0.4s;
    cursor: pointer;

    &:focus {
      outline: none;
    }

    &:focus-visible,
    &:hover {
      color: ${theme.colors.green};
      background-color: transparent !important;
    }

    ${customStyles && customStyles(theme)};
  `
);

export default StyledButton;

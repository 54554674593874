import styled, { css } from "styled-components";

export const H1 = styled.h1(
  ({ theme: { colors } }) => css`
    margin: 0 0 0.94rem;
    padding: 0.3rem 0.5rem;
    background-color: ${colors.grayF4};
    border-bottom: 0.06rem solid ${colors.grayAD};
    font-size: 1.13rem;
  `
);

import { apiHost, apiTS } from "services/httpService";

// User info with certain settings from the admin panel
export const getUserInfo = async () => {
  const { data } = await apiHost.get("/user_info/");
  return data;
};

// Help data (legend for layers)
export const getInfo = async () => {
  const { data } = await apiHost.get("/info/");
  return data;
};

// Groups with layer data (also diagnostics)
export const getGroups = async () => {
  const { data } = await apiHost.get("/group/");
  return data;
};

// Layers for search with cadastral number
export const getLayersForCadastralSearch = async () => {
  const { data } = await apiHost.get("/group/cadastral");
  return data;
};

// Dashboard list for the selected layer
export const getLayerDashboards = async (layerId) => {
  const { data } = await apiHost.get(`/dashboard/?layer_id=${layerId}`);
  return data;
};

// List of all dashboards available to the user
export const getAllDashboards = async () => {
  const { data } = await apiHost.get("/dashboard/");
  return data;
};

// List of dashboard groups
export const getDashboardGroups = async () => {
  const { data } = await apiHost.get("/section/");
  return data;
};

// List of dashboard groups for selected layer
export const getLayerDashboardGroups = async (layerId) => {
  const { data } = await apiHost.get(`/section/?layer_id=${layerId}`);
  return data;
};

// Diagnostic data
export const getDiagnostic = async (id) => {
  const { data } = await apiHost.get(`/diagnostic/${id}`);
  return data;
};

// List of request types for feedback
export const getRequestTypes = async () => {
  const { data } = await apiTS.get("/users/request-types/");
  return data;
};

// Submit request on feedback page
export const sendRequest = async (body) => {
  const { data } = await apiTS.post("/users/anon-request/", body);
  return data;
};

// Upload file on feedback page
export const uploadFile = async (body) => {
  const { data } = await apiTS.post("users/request/anon-file/", body);
  return data;
};

export const getManuals = async () => {
  const { data } = await apiHost.get("/instruction/");
  return data;
};

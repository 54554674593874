import typesOfRestrictedZones from "shared/constants/typesOfRestrictedZones";

const getFilteredLists = (list) => {
  const result = { restrictedZones: [], parcels: [] };

  list?.forEach((item) => {
    const { properties } = item || {};
    const { cdzone, number_zone, name_zone } = properties || {};

    if ("cdzone" in properties && cdzone && number_zone && name_zone) {
      result.restrictedZones.push(
        `${typesOfRestrictedZones[cdzone]} (${number_zone}): ${name_zone}`
      );
    }

    if ("cn" in properties) {
      result.parcels.push(item);
    }
  });

  return result;
};

export default getFilteredLists;

import { ThemeProvider } from "styled-components";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import { GlobalStyles, theme } from "shared/styles";
import { NotificationProvider } from "context/NotificationContext";
import { ModalsProvider } from "context/ModalsContext";

import Notifications from "components/Notifications";
import ErrorBoundary from "components/ErrorBoundary";
import Map from "screens/Map";
import Superset from "screens/Superset";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Map />,
  },
  {
    path: "/superset/:dashboardId",
    element: <Superset />,
  },
]);

function App() {
  const appHeight = () => {
    const doc = document.documentElement;
    doc.style.setProperty("--app-height", `${window.innerHeight}px`);
  };
  window.addEventListener("resize", appHeight);
  appHeight();

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />

      <NotificationProvider>
        <ErrorBoundary>
          <Notifications />

          <ModalsProvider>
            <RouterProvider router={router} />
          </ModalsProvider>
        </ErrorBoundary>
      </NotificationProvider>
    </ThemeProvider>
  );
}

export default App;

import styled, { css } from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100vw;
  min-height: 100vh;
`;

export const Message = styled.p`
  font-size: 2rem;
`;

export const HomePageLink = styled.a(
  ({ theme: { colors } }) => css`
    color: ${colors.green};
    font-size: 1rem;
    font-weight: bold;
  `
);

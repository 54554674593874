import { useEffect, useCallback, useState } from "react";

import { theme } from "shared/styles";
import { diagnosticChange } from "shared/constants/events";
import { getDiagnostic } from "services/api";
import { getParamsFromUrl } from "services/urlParamsService";

import TinyAreaChart from "components/TinyAreaChart";
import getSparkLineDataFromProperties from "./getSparkLineDataFromProperties";

const SparkLine = ({ properties, isInfoLayerPoint }) => {
  const [loading, setLoading] = useState(false);
  const [sparkLineData, setSparkLineData] = useState(null);

  const getSparkLineData = useCallback(async () => {
    const { diagnosticId } = getParamsFromUrl(["diagnosticId"]);

    try {
      if (!isInfoLayerPoint && +diagnosticId) {
        setLoading(true);
        const diagnostic = await getDiagnostic(diagnosticId);

        const data = getSparkLineDataFromProperties({
          properties,
          currentDiagnostic: diagnostic,
          colors: theme.colors,
        });
        setSparkLineData(data);
      }

      if (!isInfoLayerPoint && +diagnosticId === 0) setSparkLineData(null);
    } finally {
      setLoading(false);
    }
  }, [isInfoLayerPoint, properties]);

  useEffect(() => {
    getSparkLineData();
    const onDiagnosticChange = () => getSparkLineData();

    window.addEventListener(diagnosticChange, onDiagnosticChange);

    return () => {
      window.removeEventListener(diagnosticChange, onDiagnosticChange);
    };
  }, [getSparkLineData]);

  if (!loading && sparkLineData?.data?.length > 0) {
    return (
      <TinyAreaChart color={sparkLineData?.color} data={sparkLineData?.data} />
    );
  }

  return null;
};

export default SparkLine;

import generateLayerId from "../generateLayerId";
import addNewLayer from "../addNewLayer";

const toggleCurrentLayer = async ({
  ngwMap,
  changedLayer,
  callback,
  diagnostic,
}) => {
  try {
    const {
      resource_id: resourceId,
      service,
      is_territory_data: isInfoLayer,
    } = changedLayer;
    const currentLayerId = generateLayerId(resourceId, isInfoLayer, service);

    if (ngwMap?.isLayerVisible(currentLayerId)) {
      ngwMap?.removeLayer(currentLayerId);
    } else {
      await addNewLayer({
        ngwMap,
        layer: changedLayer,
        diagnostic,
      });
    }
  } finally {
    if (callback) callback();
  }
};

export default toggleCurrentLayer;

import styled, { css } from "styled-components";

export const TabWrapper = styled.section(
  ({ theme: { colors } }) => css`
    display: flex;
    flex-direction: column;
    height: 100%;
    color: ${colors.green};
  `
);

export const TabList = styled.div`
  position: relative;
  display: flex;
  flex: 1 0 auto;
  margin-bottom: -0.06rem;
  overflow-x: auto;
`;

export const Content = styled.div(
  ({ theme: { colors, borderRadius } }) => css`
    display: flex;
    width: 100%;
    height: 100%;
    padding: 0.63rem;
    border: 0.06rem solid ${colors.grayAD};
    border-radius: 0 ${borderRadius} ${borderRadius};
  `
);

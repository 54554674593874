const geoServiceKey = process.env.REACT_APP_ROSREESTR_KEY;

const urlById = ({ number, type }) => {
  const params = `id=${number}&type=${type}&cache=include&apikey=${geoServiceKey}`;

  return `/pkk/features/by_id?${params}`;
};

const urlByPosition = ({ lat, lng, types }) => {
  const params = `lat=${lat}&lon=${lng}&types=${types}&cache=include&apikey=${geoServiceKey}`;

  return `/pkk/features/by_pos?${params}`;
};

const generateFeatureUrl = ({ lat, lng, types, number, type }) => {
  const url = number
    ? urlById({ number, type })
    : urlByPosition({ lat, lng, types });

  return url;
};

export default generateFeatureUrl;

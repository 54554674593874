import { useState } from "react";
import Tab from "./Tab";
import { Content, TabList, TabWrapper } from "./styled";

const Tabs = ({ list, children, onTabClick }) => {
  const [activeTab, setActiveTab] = useState(list[0]?.id);

  const onClick = (index) => {
    const activeItem = list[index];

    setActiveTab(activeItem?.id);
    onTabClick(activeItem);
  };

  if (!list.length) return null;

  return (
    <TabWrapper>
      <TabList>
        {list.map(({ id: tabId, name }, index) => (
          <Tab
            key={tabId}
            id={tabId}
            type="button"
            title={name}
            isActive={tabId === activeTab}
            onClick={() => onClick(index)}
          />
        ))}
      </TabList>
      <Content>{children}</Content>
    </TabWrapper>
  );
};

export default Tabs;

import { useEffect, useState } from "react";
import { css } from "styled-components";

import { initAuth, isLoggedIn, signIn } from "services/authService";
import { RosreestrPopupProvider } from "context/RosreestrPopupContext";
import { useModalsContext } from "context/ModalsContext";
import { useNotificationContext } from "context/NotificationContext";

import NgwMap from "components/NgwMap";
import AllDashboards from "components/AllDashboards";
import Iframe from "shared/ui/Iframe";
import Loader from "shared/ui/loaders/Loader";
import ModalWindow from "shared/ui/ModalWindow";
import useGetUserInfo from "./useGetUserInfo";
import useGetPreparedData from "./useGetPreparedData";

const Map = () => {
  const isUserExist = isLoggedIn();
  const [authInitialized, setAuthInitialized] = useState(isUserExist);
  const isUserLoggedIn = authInitialized && isUserExist;

  const {
    defaultLayersData,
    businessGroups,
    infoLayers,
    loading: dataLoading,
    error: dataError,
  } = useGetPreparedData(isUserLoggedIn);
  const { modalContent, toggleModal } = useModalsContext();
  const { addNotification } = useNotificationContext();

  useEffect(() => {
    if (!authInitialized) initAuth(() => setAuthInitialized(true));
  }, [authInitialized]);

  useEffect(() => {
    if (authInitialized && !isUserExist) {
      signIn();
    }
  }, [authInitialized, isUserExist]);

  const userInfoCallback = ({ show_dashboards }) => {
    if (show_dashboards) toggleModal(<AllDashboards />);
  };

  const { userInfo, error: userInfoError } = useGetUserInfo({
    isUserLoggedIn,
    userInfoCallback,
  });
  const isDataLoaded = !dataLoading && userInfo;
  const error = dataError || userInfoError;

  useEffect(() => {
    if (error) {
      addNotification({ content: error, type: "error", isCloseable: true });
    }
  }, [error, addNotification]);

  if (isDataLoaded) {
    return (
      <>
        <RosreestrPopupProvider>
          <NgwMap
            defaultLayersData={defaultLayersData}
            businessGroups={businessGroups}
            infoLayers={infoLayers}
          />
        </RosreestrPopupProvider>

        <ModalWindow isOpen={modalContent} close={() => toggleModal()}>
          {modalContent}
        </ModalWindow>

        <Iframe
          src={process.env.REACT_APP_SUPERSET_URL}
          title="Superset login"
          customStyles={({ zIndex }) => css`
            position: absolute;
            visibility: hidden;
            z-index: ${zIndex.hiddenContent};
            width: 0;
            height: 0;
          `}
        />
      </>
    );
  }

  return <Loader text="Проверка авторизации" />;
};

export default Map;

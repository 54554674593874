import { useState, useEffect } from "react";

import {
  filterLayer,
  generateLayerId,
  moveMapToPoint,
} from "services/mapService";
import { useLayersContext } from "context/LayersContext";
import debounce from "shared/utils/debounce";
import { CADASTRAL_NUMBER_REG_EXP } from "shared/constants/regularExpressions";
import { Input } from "shared/ui/formElements";
import { CloseButton } from "shared/ui/buttons";
import { ReactComponent as SearchIcon } from "shared/icons/search.svg";
import MenuDropdown from "components/MenuDropdown";

import useLayersForCadastralSearch from "./useLayersForCadastralSearch";
import {
  SearchItem,
  SearchList,
  SearchWrapper,
  InputWrapper,
  CustomCloseButton,
} from "./styled";

const SearchField = ({ ngwMap }) => {
  const [searchValue, setSearchValue] = useState("");
  const [searchResults, setSearchResults] = useState([]);

  const {
    fetchCadastralSearchLayers,
    getCadastralDataAndLayers,
    removeCadastralDataLayers,
  } = useLayersForCadastralSearch({ ngwMap });

  const { checkedLayers, checkedInfoLayers, setLoading } = useLayersContext();
  const isSuggestionAsSearch =
    searchResults.length === 1 && searchResults[0] === searchValue;

  useEffect(() => {
    fetchCadastralSearchLayers();
  }, [fetchCadastralSearchLayers]);

  const filterData = debounce(async (searchString, isEqualToSearchResult) => {
    let result = [];
    let points = [];

    [...checkedLayers, ...checkedInfoLayers].forEach((layer) => {
      const {
        resource_id: id,
        search_fields: searchFields,
        is_territory_data: isInfoLayer,
        service,
      } = layer || {};

      const { searchResult, foundedPoint } = filterLayer({
        ngwMap,
        currentLayerId: generateLayerId(id, isInfoLayer, service),
        searchString,
        searchFields,
        isEqualToSearchResult,
      });
      result = [...result, ...searchResult].sort((a, b) => {
        const regex = new RegExp(
          `АЗС №${searchString.replace(/[^0-9]/g, "")}`,
          "g"
        );
        if (a.match(regex) && b.match(regex))
          return +a.replace(/[^0-9]/g, "") - +b.replace(/[^0-9]/g, "");
        if (String(a).match(regex)) return -1;
        return 1;
      });

      if (foundedPoint) points.push(foundedPoint);
    });

    if (result.length === 1 && points.length === 1) {
      const { longitude, latitude } = points[0].feature.properties;

      moveMapToPoint({ ngwMap, coords: [longitude, latitude], zoom: 5 });
    }

    const searchResultArr = new Set(result);
    points = [];
    setSearchResults([...searchResultArr]);
  }, 400);

  const searchByCadastralNumber = async (searchString) => {
    setLoading(true);
    setSearchValue(searchString);
    getCadastralDataAndLayers(searchString);
  };

  const searchByObjectData = ({ searchString, equalToSearch = false }) => {
    filterData(searchString, equalToSearch);
    setSearchValue(searchString);
  };

  const onChange = (event) => {
    const searchString = event.target.value.trimStart().replace(/\s\s+/g, " ");
    const isCadastralNumber = searchString.match(CADASTRAL_NUMBER_REG_EXP);

    removeCadastralDataLayers();

    if (isCadastralNumber) {
      searchByCadastralNumber(searchString);
    } else {
      searchByObjectData({ searchString });
    }
  };

  const clearInput = () => {
    removeCadastralDataLayers();
    searchByObjectData({ searchString: "" });
  };

  return (
    <SearchWrapper active={searchValue}>
      <InputWrapper>
        <MenuDropdown />
        <Input
          data-testid="search-input"
          placeholder="Поиск по объектам, кадастровому номеру"
          aria-label="Поиск по объектам, кадастровому номеру"
          value={searchValue}
          onChange={onChange}
        />
        {!searchValue && <SearchIcon />}
        {searchValue && (
          <CloseButton customStyles={CustomCloseButton} onClick={clearInput} />
        )}
      </InputWrapper>

      {!!searchResults.length && !isSuggestionAsSearch && (
        <SearchList data-testid="search-results">
          {searchResults.map((item, index) => (
            <SearchItem
              data-testid="search-result-item"
              key={`${index}_item`}
              onClick={() =>
                searchByObjectData({ searchString: item, equalToSearch: true })
              }
            >
              {item}
            </SearchItem>
          ))}
        </SearchList>
      )}
    </SearchWrapper>
  );
};

export default SearchField;

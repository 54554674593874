import { getCircleFeature } from "shared/utils/getCirclePolygon";
import generateLayerId from "../generateLayerId";
import loadMultipleImages from "../loadMultipleImages";
import generateLayerOptions from "../generateLayerOptions";

const compareDiagnostics = [15, 16, 17, 18, 19, 20];

let currentDiagnostic;

const addNewLayer = async ({ ngwMap, layer, diagnostic }) => {
  const {
    resource_id: resourceId,
    icons,
    service,
    is_territory_data: isInfoLayer,
  } = layer;
  const layerId = generateLayerId(resourceId, isInfoLayer, service);

  const removeLayer = () => {
    currentDiagnostic = null;
    ngwMap.removeLayer("pricesCompareLayer");
  };

  if (ngwMap?.isLayerVisible(layerId)) return;

  if (icons?.default_icon?.name) {
    loadMultipleImages({ ngwMap, icons, diagnostic });
  }

  const { commonOptions, adapterOptions } = await generateLayerOptions({
    ngwMap,
    layer,
    resourceId,
    diagnostic,
  });

  await ngwMap
    ?.addNgwLayer({
      id: layerId,
      resource: resourceId,
      ...commonOptions,
      adapterOptions,
    })
    .then((res) => {
      if (diagnostic && diagnostic.id === currentDiagnostic) return;
      if (compareDiagnostics.includes(diagnostic?.id)) {
        currentDiagnostic = diagnostic.id;
        const features = [];

        const points = res._features.filter(
          (point) => point.properties[diagnostic.field_name]
        );

        points.forEach((point) => {
          features.push(
            getCircleFeature(
              ...point.geometry.coordinates,
              point.properties[diagnostic.field_name]
            )
          );
        });

        ngwMap.addGeoJsonLayer({
          id: "pricesCompareLayer",
          data: {
            type: "FeatureCollection",
            features,
          },
          order: -1,
          paint: (f) => f.properties,
        });
      } else removeLayer();
    });
};

export default addNewLayer;

import styled, { css } from "styled-components";

export const Wrapper = styled.div``;

export const customButtonStyles = ({ mapBoxShadow, colors }) => css`
  box-shadow: ${mapBoxShadow};
`;

export const NavigationPopupWrapper = styled.div(
  ({ theme: { mapBoxShadow, borderRadius } }) => css`
    padding: 15px;
    position: absolute;
    top: 75px;
    left: 16px;
    z-index: 1;
    background-color: white;
    width: 480px;
    box-shadow: ${mapBoxShadow};
    border-radius: ${borderRadius};
  `
);

export const Select = styled.select(
  ({ theme: { colors } }) => css`
    min-width: 100px;
    width: 100%;
    margin-bottom: 5px;
    padding: 5px 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    background: transparent;
    font-family: "Rubik", sans-serif;
    font-size: 14px;
    color: ${colors.gray};
  `
);

export const RemoveDotUi = styled.div(
  ({ theme: { colors } }) => css`
    text-align: center;
    margin-top: 10px;
    cursor: pointer;
    color: ${colors.green};
  `
);

import styled, { css } from "styled-components";

export const Content = styled.div(
  ({ theme: { down, breakpoints } }) => css`
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    min-width: 15.63rem;
    max-height: 60vh;
    overflow: auto;
    font-size: 0.9rem;

    ${down(breakpoints.sm)} {
      min-width: auto;
    }
  `
);

export const Message = styled.p`
  text-align: center;
`;

const getAdditionalLayersData = ({ params, infoLayers }) => {
  const additionalLayersParam = params.get("additionalLayers");
  const additionalLayersIds = additionalLayersParam?.split(",");

  const filteredLayers = infoLayers?.filter(({ id }) =>
    additionalLayersIds?.some((currentId) => +id === +currentId)
  );
  const currentInfoLayers = additionalLayersIds ? filteredLayers : [];

  return currentInfoLayers;
};

export default getAdditionalLayersData;

import { ThemeProvider } from "styled-components";
import { theme } from "shared/styles";
import PopupContent from "./PopupContent";

const PointPopup = (props) => (
  <ThemeProvider theme={theme}>
    <PopupContent {...props} />
  </ThemeProvider>
);

export default PointPopup;

import {
  RadioButtonWrapper,
  NativeRadioButton,
  CustomRadioButton,
  Label,
} from "./styled";

const RadioButton = ({
  id,
  name,
  value,
  checked,
  children,
  onChange,
  customStyles,
  ...restProps
}) => {
  const testIdForRadioButton = `radio-button-${id}`;

  return (
    <RadioButtonWrapper customStyles={customStyles} {...restProps}>
      <NativeRadioButton
        id={id}
        type="radio"
        name={name}
        value={value}
        checked={checked}
        onChange={onChange}
        data-testid={testIdForRadioButton}
      />
      <CustomRadioButton checked={checked} />

      <Label htmlFor={id}>{children}</Label>
    </RadioButtonWrapper>
  );
};

export default RadioButton;

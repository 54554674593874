import { useParams, useNavigate, useSearchParams } from "react-router-dom";

import { Button } from "shared/ui/buttons";
import SupersetIframe from "components/SupersetIframe";
import {
  DashboardHeader,
  Arrow,
  backButtonStyles,
  Title,
} from "components/AllDashboards/styled";
import { DashboardWrapper } from "./styled";

function Superset() {
  const { dashboardId } = useParams();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  return (
    <DashboardWrapper>
      <DashboardHeader>
        <Button
          isIconButton
          title="Назад к списку"
          customStyles={backButtonStyles}
          onClick={() => navigate("/")}
        >
          <Arrow />
        </Button>

        <Title>{searchParams.get("name")}</Title>
      </DashboardHeader>

      <SupersetIframe dashboardId={dashboardId} />
    </DashboardWrapper>
  );
}

export default Superset;

const getSimpleExpressionForIcons = ({
  defaultIcon = {},
  additionalIcons = [],
}) => {
  const { name: defaultIconName } = defaultIcon;

  if (additionalIcons?.length > 0) {
    const result = ["match", ["get", "icon"]];

    additionalIcons.forEach(({ name }) => {
      result.push(name);
      result.push(name);
    });
    result.push(defaultIconName);
    return result;
  }

  return defaultIconName;
};

export default getSimpleExpressionForIcons;

import { useCallback, useMemo, useState } from "react";
import ModalsContext from "./ModalsContext";

const ModalsProvider = ({ children }) => {
  const [modalContent, setModalContent] = useState(null);

  const toggleModal = useCallback((content = null) => {
    setModalContent(content);
  }, []);

  const context = useMemo(
    () => ({ modalContent, toggleModal }),
    [modalContent, toggleModal]
  );

  return (
    <ModalsContext.Provider value={context}>{children}</ModalsContext.Provider>
  );
};

export default ModalsProvider;

import { StyledIframe } from "./styled";

const Iframe = ({ src, title, customStyles, ...restProps }) => (
  <StyledIframe
    seamless
    src={src}
    title={title}
    customStyles={customStyles}
    sandbox="allow-same-origin allow-scripts allow-presentation allow-modals allow-popups allow-forms allow-downloads"
    {...restProps}
  />
);

export default Iframe;

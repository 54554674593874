const getDiagnosticData = ({ params, currentGroup }) => {
  const diagnosticIdParam = params.get("diagnosticId");
  const checkedDiagnostic = currentGroup?.diagnostics.find(
    (item) => +item.id === +diagnosticIdParam
  );
  const groupDiagnostic = checkedDiagnostic || null;

  return { diagnosticId: +diagnosticIdParam, groupDiagnostic };
};

export default getDiagnosticData;

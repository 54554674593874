import { useEffect, useState, useCallback } from "react";

import { useLayersContext } from "context/LayersContext";
import { addLayersParamsToUrl } from "services/layersService";
import { getParamsFromUrl } from "services/urlParamsService";

import LayersList from "../LayersList";
import { Group, SectionWrapper } from "./styled";

const LayersGroup = ({
  layers,
  diagnostics,
  currentGroupId,
  removePreviouslySelectedLayers,
}) => {
  const { groupId: selectedGroupId, diagnosticId: selectedDiagnosticId } =
    getParamsFromUrl(["groupId", "diagnosticId"]);
  const isAnotherGroupSelected = +currentGroupId !== +selectedGroupId;
  const { checkedLayers, setCheckedLayers } = useLayersContext();

  const defaultCheckedLayers = isAnotherGroupSelected ? [] : checkedLayers;
  const [groupCheckedLayers, setGroupCheckedLayers] =
    useState(defaultCheckedLayers);

  const currentDiagnostic = diagnostics.find(
    ({ id }) => +id === +selectedDiagnosticId
  );
  const defaultDiagnostic =
    +selectedDiagnosticId && !isAnotherGroupSelected ? currentDiagnostic : null;
  const [checkedDiagnostic, setCheckedDiagnostic] = useState(defaultDiagnostic);

  const saveCheckedLayers = useCallback(
    (updatedLayers, diagnosticId) => {
      setGroupCheckedLayers(updatedLayers);
      setCheckedLayers(updatedLayers);
      addLayersParamsToUrl({
        baseLayers: updatedLayers,
        groupId: currentGroupId,
        diagnosticId,
      });
    },
    [currentGroupId, setCheckedLayers]
  );

  useEffect(() => {
    if (isAnotherGroupSelected) {
      setGroupCheckedLayers([]);
    }
  }, [isAnotherGroupSelected, setGroupCheckedLayers]);

  // Update list of the selected layers when enabling/disabling layers from another places (example: when user searches by cadastral number)
  useEffect(() => {
    setGroupCheckedLayers(checkedLayers);
    setCheckedDiagnostic(defaultDiagnostic);
  }, [checkedLayers, defaultDiagnostic]);

  return (
    <Group>
      {layers.length > 0 && (
        <SectionWrapper>
          <LayersList
            layers={layers}
            currentGroupId={currentGroupId}
            isAnotherGroupSelected={isAnotherGroupSelected}
            groupCheckedLayers={groupCheckedLayers}
            checkedDiagnostic={checkedDiagnostic}
            saveCheckedLayers={saveCheckedLayers}
            removePreviouslySelectedLayers={removePreviouslySelectedLayers}
          />
        </SectionWrapper>
      )}
    </Group>
  );
};

export default LayersGroup;

import { MapControl } from "@nextgis/react-ngw-map";

import { LayersProvider } from "context/LayersContext";

import SearchField from "components/SearchField";
import { RosreestrLayerButton } from "components/rosreestr";
import NavigationButton from "components/NavigationButton";

import LayersPanel from "components/LayersPanel";
import { MapPanelWrapper } from "./styled";

const MapPanel = ({
  groups,
  infoLayers,
  ngwMap,
  loading,
  setLoading,
  currentGroupId,
  currentLayers,
  currentDiagnostic,
  currentInfoLayers,
}) => (
  <LayersProvider
    ngwMap={ngwMap}
    loading={loading}
    setLoading={setLoading}
    currentGroupId={currentGroupId}
    currentLayers={currentLayers}
    currentDiagnostic={currentDiagnostic}
    currentInfoLayers={currentInfoLayers}
  >
    <MapControl position="top-left">
      <MapPanelWrapper>
        <SearchField ngwMap={ngwMap} />
      </MapPanelWrapper>
    </MapControl>

    <MapControl position="top-right">
      <LayersPanel groups={groups} infoLayers={infoLayers} />
    </MapControl>

    <NavigationButton ngwMap={ngwMap} />
    <RosreestrLayerButton ngwMap={ngwMap} />
  </LayersProvider>
);
export default MapPanel;

import { useState } from "react";

import SupersetIframe from "components/SupersetIframe";
import Tabs from "shared/ui/DropdownTabs";
import { Message } from "./styled";

const LayerDashboards = ({
  objectData = {},
  dashboardList = [],
  dashboardGroups = [],
}) => {
  const [activeDashboard, setActiveDashboard] = useState(
    dashboardList[0] || {}
  );

  if (!dashboardList.length) {
    return <Message>Дашборды отсутствуют</Message>;
  }

  return (
    <Tabs
      list={dashboardList}
      groups={dashboardGroups}
      onTabClick={setActiveDashboard}
    >
      <SupersetIframe
        dashboardId={activeDashboard?.superset_dashboard_id}
        filters={activeDashboard?.filters}
        objectData={objectData}
      />
    </Tabs>
  );
};

export default LayerDashboards;

import { checkLayerGeometryType } from "services/mapService";
import styled, { css } from "styled-components";

export const List = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 0.63rem;
  margin: 0;
  padding: 0.63rem;
  list-style: none;
  text-align: left;
  font-size: 0.9rem;
`;

export const Item = styled.li`
  display: flex;
  align-items: center;
  gap: 0.63rem;
  font-size: inherit;
`;

const commonStyles = () => css`
  width: 1.56rem;
  height: 1.56rem;
`;

export const ItemColor = styled.span(({ theme, color, vectorType }) => {
  const { isPointLayer, isLinesLayer } = checkLayerGeometryType(vectorType);

  return css`
    ${commonStyles({ theme })}
    background-color: ${color};
    ${isPointLayer &&
    css`
      border-radius: 50%;
      border: 0.13rem solid ${color};
      background-color: ${color.includes("#") ? `${color}80` : color};
    `}
    ${isLinesLayer &&
    css`
      width: 0.2rem;
      height: 1.56rem;
      transform: rotate(45deg);
      box-shadow: 0 0 0.3rem 0.1rem ${theme.colors.grayF4};
    `}
  `;
});

export const ItemIcon = styled.img(({ theme, vectorType }) => {
  const { isPolygonsLayer } = checkLayerGeometryType(vectorType);

  return css`
    ${commonStyles({ theme })}
    ${isPolygonsLayer &&
    css`
      width: 2rem;
      height: 2rem;
    `}
  `;
});

import ImageGallery from "react-image-gallery";
import { EmptyText, ImageWrapper } from "./styled";

const ImageViewer = ({ photos }) => (
  <ImageWrapper>
    {photos?.length > 0 && (
      <ImageGallery
        items={photos.map((photo) => ({ original: photo }))}
        showThumbnails={false}
        showPlayButton={false}
        showIndex
      />
    )}

    {!photos || (photos.length === 0 && <EmptyText>Нет фотографий</EmptyText>)}
  </ImageWrapper>
);

export default ImageViewer;

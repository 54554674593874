import Notification from "./Notification";
import { Wrapper } from "./styled";

const Notifications = ({ list, removeItem }) => {
  if (list.length === 0) return null;

  return (
    <Wrapper>
      {list.map((item) => (
        <Notification key={item.id} {...item} removeFromList={removeItem} />
      ))}
    </Wrapper>
  );
};

export default Notifications;

import { useMemo, useState } from "react";
import LayersContext from "./LayersContext";

const LayersProvider = ({
  children,
  ngwMap,
  loading,
  setLoading,
  currentLayers,
  currentInfoLayers,
  currentDiagnostic,
}) => {
  const [checkedLayers, setCheckedLayers] = useState(currentLayers);
  const [checkedInfoLayers, setCheckedInfoLayers] = useState(currentInfoLayers);
  const [checkedDiagnostic, setCheckedDiagnostic] = useState(currentDiagnostic);

  const context = useMemo(
    () => ({
      ngwMap,
      loading,
      setLoading,
      checkedLayers,
      setCheckedLayers,
      checkedInfoLayers,
      setCheckedInfoLayers,
      checkedDiagnostic,
      setCheckedDiagnostic,
    }),
    [
      ngwMap,
      loading,
      setLoading,
      checkedLayers,
      setCheckedLayers,
      checkedInfoLayers,
      setCheckedInfoLayers,
      checkedDiagnostic,
      setCheckedDiagnostic,
    ]
  );

  return (
    <LayersContext.Provider value={context}>{children}</LayersContext.Provider>
  );
};

export default LayersProvider;

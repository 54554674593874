const getIconsFromDiagnostic = (diagnostic) => {
  if (diagnostic) {
    const { color_values } = diagnostic || {};
    return color_values?.map(({ icon }) => icon);
  }

  return null;
};

export default getIconsFromDiagnostic;

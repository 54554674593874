import { useState, useEffect, useCallback } from "react";

import { getManuals } from "services/api";
import { useNotificationContext } from "context/NotificationContext";

import Loader from "shared/ui/loaders/Loader";
import Iframe from "shared/ui/Iframe";
import Tabs from "shared/ui/Tabs";
import {
  Wrapper,
  ListWrapper,
  List,
  listButtonStyles,
  DashboardWrapper,
  DashboardHeader,
  Arrow,
  backButtonStyles,
  Title,
  Group,
  iframeStyles,
} from "./styled";

const AllDashboards = () => {
  const [manuals, setManuals] = useState([]);
  const [selectedManual, setSelectedManual] = useState();
  const [loading, setLoading] = useState(true);
  const { addNotification } = useNotificationContext();

  const fetchManuals = useCallback(async () => {
    try {
      const data = await getManuals();
      if (data) {
        setManuals(data);
        setSelectedManual(data[0]);
      }
    } catch (error) {
      addNotification({ content: error, type: "error" });
    }
  }, [addNotification]);

  // const showCurrentDashboard = (event, id, name) => {
  //   event.preventDefault();
  //   setDashboardId(id);
  //   setDashboardName(name);
  // };

  useEffect(() => {
    fetchManuals();
    setLoading(false);
  }, [fetchManuals]);

  return (
    <Wrapper>
      {loading && <Loader text="Загрузка данных" position="absolute" />}

      {!loading && selectedManual && (
        <Tabs list={manuals} onTabClick={setSelectedManual}>
          <Iframe
            src={selectedManual.content}
            title={selectedManual.name}
            customStyles={iframeStyles}
          />
        </Tabs>
      )}

      {/* {allDashboards?.length > 0 && !dashboardId && (
        <ListWrapper>
          {dashboardGroups?.map(({ dashboard_list, id, name }) => (
            <Group key={id}>
              {name && <GroupTitle>{name}</GroupTitle>}
              {getDashboardsByIds(dashboard_list)?.map((dashboard) => (
                <LinkButton
                  key={dashboard.id}
                  onClick={(e) =>
                    showCurrentDashboard(
                      e,
                      dashboard.superset_dashboard_id,
                      dashboard.name
                    )
                  }
                  customStyles={listButtonStyles}
                  href={`superset/${dashboard.superset_dashboard_id}?name=${dashboard.name}`}
                >
                  {dashboard.name}
                </LinkButton>
              ))}
            </Group>
          ))}
          {getDashboardsWithoutGroup?.length > 0 && (
            <div>
              <GroupTitle>Без группы</GroupTitle>
              <List>
                {getDashboardsWithoutGroup?.map(
                  ({ superset_dashboard_id, id, name }) => (
                    <LinkButton
                      key={id}
                      onClick={(e) =>
                        showCurrentDashboard(e, superset_dashboard_id, name)
                      }
                      customStyles={listButtonStyles}
                      href={`superset/${superset_dashboard_id}?name=${name}`}
                    >
                      {name}
                    </LinkButton>
                  )
                )}
              </List>
            </div>
          )}
        </ListWrapper>
      )}

      {dashboardId && (
        <DashboardWrapper>
          <DashboardHeader>
            <Button
              isIconButton
              title="Назад к списку"
              customStyles={backButtonStyles}
              onClick={() => setDashboardId(null)}
            >
              <Arrow />
            </Button>

            <Title>{dashboardName}</Title>
          </DashboardHeader>

          {dashboardId && <SupersetIframe dashboardId={dashboardId} />}
        </DashboardWrapper>
      )} */}
    </Wrapper>
  );
};

export default AllDashboards;

import {
  getIconExpressionForDiagnostics,
  getSimpleExpressionForIcons,
} from "services/mapService/getExpressions";

const generateMarkerOptions = ({ icons, diagnostic }) => {
  const { default_icon: defaultIcon, additional_icons: additionalIcons } =
    icons || {};
  const { name: defaultIconName } = defaultIcon || {};
  const diagnosticIconExpression =
    diagnostic &&
    getIconExpressionForDiagnostics({
      colorData: diagnostic,
      defaultIcon: defaultIconName,
    });

  const iconExpression = additionalIcons?.length
    ? getSimpleExpressionForIcons({ defaultIcon, additionalIcons })
    : defaultIconName;

  return {
    layout: {
      "icon-image": diagnostic ? diagnosticIconExpression : iconExpression,
      "icon-size": 0.3,
      "icon-allow-overlap": true,
    },
    paint: {},
    selectedPaint: {
      "icon-opacity": 1,
    },
    nativeOptions: { type: "symbol" },
    nativePaint: true,
  };
};

export default generateMarkerOptions;

import styled, { css } from "styled-components";

export const AdditionalData = styled.div(
  ({ theme: { colors }, isMultipleParcels }) => css`
    display: flex;
    flex-direction: column;
    margin-bottom: 0.63rem;
    ${isMultipleParcels &&
    css`
      padding-top: 0.94rem;
      margin-top: 0.94rem;
      border-top: 0.06rem solid ${colors.grayAD};
    `}
  `
);

export const DataList = styled.ul(
  ({ theme: { colors, down, breakpoints } }) => css`
    display: block;
    margin: 0;
    padding: 0;
    list-style: none;
    text-align: left;

    & + ${DataList} {
      padding-top: 0.94rem;
      margin-top: 0.94rem;
      border-top: 0.06rem solid ${colors.grayAD};
    }

    ${down(breakpoints.sm)} {
      max-height: 12.5rem;
    }
  `
);

export const Value = styled.span`
  display: flex;
  flex-direction: column;

  & + span {
    margin-top: 0.63rem;
  }
`;

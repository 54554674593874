import checkLayerGeometryType from "../checkLayerGeometryType";
import generateColorOptions from "./generateColorOptions";
import generateMarkerOptions from "./generateMarkerOptions";
import generatePopupOptions from "./generatePopupOptions";

const generateLayerOptions = async ({
  ngwMap,
  layer,
  resourceId,
  diagnostic,
}) => {
  const {
    id,
    icons,
    colors,
    service,
    view_fields: viewFields,
    render_properties: renderProperties,
    is_territory_data: isInfoLayer,
  } = layer;

  const resource = await ngwMap?.connector.getResourceById(resourceId);
  const { vector_layer: vectorLayer } = resource || {};
  const { geometry_type: geometryType } = vectorLayer || {};

  const isRosreestrLayer = service === "rosreestr";
  const isSelectable = viewFields?.length > 0 || isRosreestrLayer;

  const { isPointLayer } = checkLayerGeometryType(geometryType);
  const infoLayerOrder = isPointLayer ? 2 : 1;
  const order = isInfoLayer ? infoLayerOrder : 3;

  const popupOptions = !viewFields?.length
    ? {}
    : generatePopupOptions({ id, resource, viewFields, isInfoLayer });

  const paintLayoutOptions = icons?.default_icon?.name
    ? generateMarkerOptions({ ngwMap, icons, diagnostic })
    : generateColorOptions({ colors, geometryType, renderProperties });

  const { opacity } = renderProperties || {};
  const opacityOptions = opacity ? { opacity } : {};
  const limitOptions = isInfoLayer ? { limit: Infinity } : {};

  return {
    commonOptions: {
      order,
      ...limitOptions,
    },
    adapterOptions: {
      selectable: isSelectable,
      ...opacityOptions,
      ...popupOptions,
      ...paintLayoutOptions,
    },
  };
};

export default generateLayerOptions;

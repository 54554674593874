import { ButtonHtml } from "./styled";

function Button({
  type = "button",
  children,
  loading = false,
  disabled = false,
  active = false,
  onClick = () => {},
  isIconButton,
  isWhite,
  withShadow,
  customRef,
  customStyles,
  ...rest
}) {
  return (
    <ButtonHtml
      ref={customRef}
      type={type}
      onClick={onClick}
      disabled={loading || disabled}
      active={active}
      isIconButton={isIconButton}
      isWhite={isWhite}
      withShadow={withShadow}
      customStyles={customStyles}
      {...rest}
    >
      {children}
    </ButtonHtml>
  );
}

export default Button;

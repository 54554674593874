import { getColorFromDiagnostic } from "services/diagnosticService";

const getSparkLineDataFromProperties = ({
  properties,
  currentDiagnostic,
  colors,
}) => {
  const { field_name } = currentDiagnostic || {};

  const color = getColorFromDiagnostic({
    diagnostic: currentDiagnostic,
    fieldValue: properties[field_name],
    colors,
  });
  const sparkLineData = properties[`${field_name}_sparkline`];

  return {
    color,
    data: sparkLineData ? JSON.parse(sparkLineData) : null,
  };
};

export default getSparkLineDataFromProperties;

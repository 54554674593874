import styled, { css } from "styled-components";

export const TabButton = styled.button(
  ({ theme: { colors }, isActive }) => css`
    padding: 0.63rem;
    width: 100%;
    height: auto !important;
    border: none;
    border-radius: 0 !important;
    background-color: ${colors.white};
    transition: all 0.2s;
    ${isActive &&
    css`
      background-color: ${colors.green};
      color: ${colors.white};
    `}

    &:focus,
    &:hover {
      background-color: ${isActive ? colors.black : colors.grayEB};
    }
  `
);

import {
  searchParams,
  addParamsToUrl,
  removeParamsFromUrl,
} from "services/urlParamsService";
import { diagnosticChange } from "shared/constants/events";

const addLayersParamsToUrl = ({
  baseLayers,
  additionalLayers,
  groupId,
  diagnosticId = 0,
}) => {
  const baseLayersIds = baseLayers?.map((item) => item.id);
  const additionalLayersIds = additionalLayers?.map((item) => item.id);
  const baseLayersParams = baseLayersIds?.toString();
  const additionalLayersParams = additionalLayersIds?.toString();

  const diagnosticEvent = new Event(diagnosticChange);
  const currentDiagnosticId = searchParams.get("diagnosticId");
  const isDiagnosticChanged = diagnosticId !== +currentDiagnosticId;

  if (baseLayers) {
    if (!baseLayersIds?.length) {
      removeParamsFromUrl(["layers", "groupId", "diagnosticId"]);
    } else {
      addParamsToUrl([
        { name: "layers", value: baseLayersParams },
        { name: "groupId", value: groupId },
        { name: "diagnosticId", value: diagnosticId },
      ]);
    }
  }

  if (additionalLayers) {
    if (!additionalLayersIds?.length) {
      searchParams.delete("additionalLayers");
    } else {
      searchParams.set("additionalLayers", additionalLayersParams);
    }
  }

  window.history.replaceState(
    null,
    "",
    `/?${decodeURIComponent(searchParams.toString())}`
  );

  if (isDiagnosticChanged) window.dispatchEvent(diagnosticEvent);
};

export default addLayersParamsToUrl;

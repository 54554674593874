import { useEffect, useState } from "react";

import Loader from "shared/ui/loaders/Loader";
import List from "./List";

import { Content, Message } from "./styled";

const RosReestrData = ({ loading, rosReestrData }) => {
  const [list, setList] = useState(rosReestrData || []);

  useEffect(() => {
    setList(rosReestrData);
  }, [rosReestrData]);

  return (
    <Content>
      {loading && <Loader position="static" text="Запрос данных" />}

      {!loading && list?.length > 0 && <List list={list} />}

      {!loading && list?.length === 0 && (
        <Message>Нет данных об участке</Message>
      )}
    </Content>
  );
};

export default RosReestrData;

const debounce = (callback, delay = 250) => {
  let timeout;

  return (...args) => {
    if (timeout) clearTimeout(timeout);

    timeout = setTimeout(() => callback(...args), delay);
  };
};

export default debounce;

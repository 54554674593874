import { useState } from "react";
import Tab from "./Tab";
import { Content, customDropdownStyles, TabList, TabWrapper } from "./styled";
import Dropdown from "../Dropdown";

const Tabs = ({ list, groups, children, onTabClick }) => {
  const [activeTab, setActiveTab] = useState(list[0]?.id);

  const onClick = (id) => {
    const activeItem = list.find((el) => el.id === id);

    setActiveTab(id);
    onTabClick(activeItem);
  };

  const getItemsByIds = (ids) => list.filter((item) => ids.includes(item.id));

  const getItemsWithoutGroup = list.filter((item) => {
    for (let i = 0; i < groups.length; i += 1) {
      if (groups[i].dashboard_list.includes(item.id)) return false;
    }
    return true;
  });

  if (!list.length) return null;

  return (
    <TabWrapper>
      <TabList>
        {groups?.map(({ id, name, dashboard_list }) => (
          <Dropdown
            key={id}
            data={getItemsByIds(dashboard_list)?.map((dashboard) => (
              <Tab
                key={dashboard.id}
                id={dashboard.id}
                type="button"
                title={dashboard.name}
                isActive={dashboard.id === activeTab}
                onClick={() => onClick(dashboard.id)}
              />
            ))}
            buttonType="link"
            buttonText={name}
            isActive={dashboard_list.includes(activeTab)}
            customStyles={customDropdownStyles}
          />
        ))}
        {getItemsWithoutGroup.length > 0 && (
          <Dropdown
            key="without-group-dropdown"
            data={getItemsWithoutGroup.map(({ id: tabId, name }, index) => (
              <Tab
                key={tabId}
                id={tabId}
                type="button"
                title={name}
                isActive={tabId === activeTab}
                onClick={() => onClick(tabId)}
              />
            ))}
            buttonType="link"
            buttonText="Без группы"
            customStyles={customDropdownStyles}
          />
        )}
      </TabList>
      <Content>{children}</Content>
    </TabWrapper>
  );
};

export default Tabs;

import { useRef, useState } from "react";

import useClickOutside from "shared/hooks/useClickOutside";
import DropdownButton from "./DropdownButton";

import { DropdownItem, DropdownList, Wrapper } from "./styled";

const Dropdown = ({
  list = [],
  icon,
  buttonType,
  buttonText,
  customStyles,
  data,
  isActive,
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const dropdownRef = useRef();
  useClickOutside(dropdownRef, () => setIsVisible(false));

  return (
    <Wrapper ref={dropdownRef} customStyles={customStyles}>
      <DropdownButton
        buttonText={buttonText}
        buttonType={buttonType}
        icon={icon}
        setIsVisible={setIsVisible}
        isActive={isActive}
      />

      {isVisible && !data && (
        <DropdownList>
          {list.map(({ id, item }) => (
            <DropdownItem key={id}>{item}</DropdownItem>
          ))}
        </DropdownList>
      )}

      {isVisible && data && (
        <DropdownList onClick={() => setIsVisible(false)}>{data}</DropdownList>
      )}
    </Wrapper>
  );
};

export default Dropdown;

import {
  point,
  multiPoints,
  line,
  multiLines,
  polygon,
  multiPolygons,
} from "shared/constants/layerTypes";

const checkLayerGeometryType = (geometryType) => {
  const isPointLayer = geometryType === point || geometryType === multiPoints;
  const isLinesLayer = geometryType === line || geometryType === multiLines;
  const isPolygonsLayer =
    geometryType === polygon || geometryType === multiPolygons;

  return {
    isPointLayer,
    isLinesLayer,
    isPolygonsLayer,
  };
};

export default checkLayerGeometryType;

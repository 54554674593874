import styled from "styled-components";

export const Group = styled.section``;

export const SectionWrapper = styled.fieldset`
  margin: 0;
  padding: 0;
  border: 0;

  &:not(:last-child) {
    margin-bottom: 0.94rem;
  }
`;

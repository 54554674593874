import { useCallback, useEffect, useState } from "react";
import { MapControl } from "@nextgis/react-ngw-map";
import importantObjects from "data/importantObjects.json";
import { Button } from "shared/ui/buttons";
import useIsZoomSmall from "shared/hooks/useIsZoomSmall";
import { useNotificationContext } from "context/NotificationContext";
import { ZOOM_FOR_NAVIGATION_BUTTON } from "shared/constants/mapConstants";
import { fetchRoute } from "services/valhallaService";
import { ReactComponent as RouteAltIcon } from "shared/icons/route-alt.svg";
import decodeShape from "./decodeShape";
import DestinationsPopup from "./DestinationsPopup";
import { Wrapper, customButtonStyles } from "./styled";

const NAVIGATION_START_POINTER_PREFIX = "navigation_start_pointer";

function getNavigationStartPointerLayerId(
  fromCoordinates,
  isLoading,
  hasRouteLength
) {
  let navigationStartPointerLayerId = `${NAVIGATION_START_POINTER_PREFIX}_${fromCoordinates[0]}_${fromCoordinates[1]}`;
  if (isLoading) {
    navigationStartPointerLayerId += `_loading`;
  }

  if (hasRouteLength) {
    navigationStartPointerLayerId += "_has_router_length";
  }

  return navigationStartPointerLayerId;
}

const NAVIGATION_ROUTE_LAYER_PREFIX = "navigation_route_id";

function getNavigationRouteLayerId(navigation) {
  return `${NAVIGATION_ROUTE_LAYER_PREFIX}_${navigation.from[0]}_${
    navigation.from[1]
  }_${navigation.to[0] || navigation.to.coordinates.lon}_${
    navigation.to[1] || navigation.to.coordinates.lat
  }`;
}

const defaultNavigationState = {
  from: null,
  to: null,
  length: null,
};

const NavigationButton = ({ ngwMap }) => {
  const isZoomSmall = useIsZoomSmall(ngwMap, ZOOM_FOR_NAVIGATION_BUTTON);
  const [isNavigationEnabled, setIsNavigationEnabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { addNotification } = useNotificationContext();
  const [navigation, setNavigation] = useState(defaultNavigationState);

  const resetNavigationState = () => setNavigation(defaultNavigationState);

  const cleanUpPointerLayer = useCallback(() => {
    if (navigation.from && navigation.to) {
      ngwMap?.removeLayer(
        `${NAVIGATION_START_POINTER_PREFIX}_${navigation.from[0]}_${navigation.from[1]}`
      );
    }
  }, [navigation, ngwMap]);

  const cleanUpAllNavigationsLayers = useCallback(() => {
    ngwMap?.removeLayers((l) => l.startsWith(NAVIGATION_START_POINTER_PREFIX));
    ngwMap?.removeLayers((l) => l.startsWith(NAVIGATION_ROUTE_LAYER_PREFIX));
  }, [ngwMap]);

  const cleanUpRouteLayer = useCallback(() => {
    if (navigation.from && navigation.to) {
      ngwMap?.removeLayer(getNavigationRouteLayerId(navigation));
    } else {
      ngwMap?.removeLayers((l) => l.startsWith(NAVIGATION_ROUTE_LAYER_PREFIX));
    }
  }, [navigation, ngwMap]);

  const drawRouteForSelectedObject = useCallback(async () => {
    if (
      !navigation.from ||
      !navigation.to ||
      ngwMap.findLayer(
        (l) => l.options.id === getNavigationRouteLayerId(navigation)
      )
    )
      return;

    try {
      setIsLoading(true);
      ngwMap?.removeLayers((l) => l.startsWith(NAVIGATION_ROUTE_LAYER_PREFIX));
      const data = await fetchRoute({
        fromCoordinates: {
          lon: navigation.from[0],
          lat: navigation.from[1],
        },
        toCoordinates: {
          lon: navigation.to[0] || navigation.to.coordinates.lon,
          lat: navigation.to[1] || navigation.to.coordinates.lat,
        },
      });
      const { shape } = data.trip.legs[0];
      const { length } = data.trip.summary;
      setNavigation((prev) => ({
        ...prev,
        length: Math.round(length),
      }));
      const coordinates = decodeShape(shape);
      const route = {
        type: "FeatureCollection",
        features: [
          {
            type: "Feature",
            properties: {},
            geometry: { type: "LineString", coordinates },
          },
        ],
      };

      await ngwMap.addGeoJsonLayer({
        id: getNavigationRouteLayerId(navigation),
        data: route,
        paint: { color: "#9900CC", opacity: 0.8, weight: 5 },
      });
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      if (error?.response.status === 400) {
        addNotification({
          content: error.response?.data?.error,
          type: "error",
        });
      } else {
        addNotification({ content: "Something went wrong", type: "error" });
      }
    }
  }, [ngwMap, navigation, addNotification]);

  useEffect(() => {
    drawRouteForSelectedObject();
  }, [drawRouteForSelectedObject]);

  const cleanUpNavigationLayers = useCallback(() => {
    cleanUpRouteLayer();
    cleanUpPointerLayer();
  }, [cleanUpPointerLayer, cleanUpRouteLayer]);

  const drawStartPointerPopup = useCallback(async () => {
    if ((isLoading && navigation.length) || !navigation.from) {
      return;
    }

    const id = getNavigationStartPointerLayerId(
      navigation.from,
      isLoading,
      navigation.length
    );
    if (ngwMap.findLayer((l) => l.options.id === id)) {
      return;
    }

    ngwMap.removeLayers(
      (layerId) =>
        layerId.startsWith(NAVIGATION_START_POINTER_PREFIX) && layerId !== id
    );

    await ngwMap?.addGeoJsonLayer({
      id,
      data: {
        type: "Feature",
        geometry: { type: "Point", coordinates: navigation.from },
      },
      selectable: true,
      paint: { color: "green", radius: 6 },
    });
  }, [navigation, ngwMap, isLoading]);

  const addSecondPoint = useCallback(
    async ({ lngLat }) => {
      setNavigation({
        ...navigation,
        to: lngLat,
      });
    },
    [navigation]
  );

  const drawSecondPoint = useCallback(async () => {
    if (!navigation.to) return;
    const id = getNavigationStartPointerLayerId(
      navigation.to,
      isLoading,
      navigation.length
    );
    if (ngwMap.findLayer((l) => l.options.id === id)) {
      return;
    }

    await ngwMap?.addGeoJsonLayer({
      id,
      data: {
        type: "Feature",
        geometry: { type: "Point", coordinates: navigation.to },
      },
      selectable: true,
      paint: { color: "green", radius: 6 },
    });
  }, [navigation, ngwMap, isLoading]);

  useEffect(() => {
    if (!navigation.from) return;
    if (!navigation.to) {
      drawStartPointerPopup();
    } else {
      drawSecondPoint();
    }
  }, [drawStartPointerPopup, drawSecondPoint, navigation]);

  const updateStartCoordinates = useCallback(
    async ({ lngLat }) => {
      await cleanUpNavigationLayers();
      setNavigation({
        from: lngLat,
        to: null,
        length: null,
      });
    },
    [cleanUpNavigationLayers]
  );

  const clickOnMap = useCallback(
    ({ lngLat }) => {
      if (!navigation.from || (navigation.from && navigation.to))
        updateStartCoordinates({ lngLat });
      else {
        addSecondPoint({ lngLat });
      }
    },
    [navigation, updateStartCoordinates, addSecondPoint]
  );

  useEffect(() => {
    if (isNavigationEnabled) {
      ngwMap?.emitter.on("click", clickOnMap);

      return () => {
        ngwMap?.emitter.removeListener("click", clickOnMap);
      };
    }

    // ngwMap?.emitter.removeAllListeners("click");
    resetNavigationState();
    cleanUpAllNavigationsLayers();
  }, [
    isNavigationEnabled,
    ngwMap,
    updateStartCoordinates,
    cleanUpAllNavigationsLayers,
    clickOnMap,
  ]);

  if (isZoomSmall) return null;

  return (
    <Wrapper>
      <MapControl position="bottom-right">
        <Button
          isIconButton
          isWhite
          title="Режим навигации"
          isActive={isNavigationEnabled}
          onClick={() => setIsNavigationEnabled((prev) => !prev)}
          customStyles={customButtonStyles}
        >
          <RouteAltIcon />
        </Button>
      </MapControl>

      {isNavigationEnabled && (
        <DestinationsPopup
          isLoading={isLoading}
          onChange={(event) => {
            const object = importantObjects.find(
              ({ name }) => name === event.target.value
            );
            setNavigation((prev) => ({
              ...prev,
              to: object /* length: null */,
              length: null,
            }));
          }}
          removePointer={() => {
            resetNavigationState();
            cleanUpAllNavigationsLayers();
          }}
          navigation={navigation}
          selected={navigation.to ? navigation.to.name || "Точка" : ""}
        />
      )}
    </Wrapper>
  );
};

export default NavigationButton;

import { LAYER } from "shared/constants/layerIdPostfixes";
import getVisibleLayerIdsByName from "../getVisibleLayerIdsByName";
import removeLayer from "../removeLayer";

const removeAllVisibleLayers = ({ ngwMap }) => {
  const allLayerIds = getVisibleLayerIdsByName({
    ngwMap,
    subString: LAYER,
  });

  if (allLayerIds.length > 0) {
    allLayerIds.forEach((layerId) => {
      removeLayer({ ngwMap, layerId });
    });
  }
};

export default removeAllVisibleLayers;

import styled, { css } from "styled-components";

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: auto;
  padding: 20px;
`;

export const FormWrapper = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const InputGroup = styled.div`
  position: relative;
  width: 100%;
  max-width: 500px;
  margin-bottom: 30px;
`;

export const FormElement = styled.div`
  height: 64px;
  width: 100%;
  border-radius: 8px;
  padding: 10px 17px;
  background: #f9fcff;
  border: 1px solid #e7eef4;
  font: 400 16px sans-serif;
  ${({ error }) =>
    error &&
    `
    border-color: #e84241;
  `}
`;

export const Button = styled(FormElement)`
  height: 56px;
  max-width: 500px;
  border: none;
  background: #53b178;
  color: #fff;
  text-transform: uppercase;
  ${({ disabled }) =>
    disabled &&
    `
    opacity: 0.6;
  `}
`;

export const Error = styled.div`
  position: absolute;
  bottom: -20px;
  left: 16px;
  font-size: 13px;
  color: #e84241;
`;

export const Completed = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  font-size: 24px;
`;

export const FileBlock = styled.div`
  margin-bottom: 38px;
  max-width: 500px;
`;

export const FileList = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  gap: 10px;
  margin-top: 30px;
  margin-bottom: 10px;
`;

export const FileListItem = styled.div`
  position: relative;
  aspect-ratio: 1/1;

  img {
    height: 100%;
    width: 100%;
    border-radius: 8px;
    object-fit: cover;
  }
`;

export const FileInput = styled.input`
  display: none;
`;

export const FileButton = styled.button`
  height: 56px;
  width: 219px;
  background: #e7eef4;
  border: none;
  border-radius: 8px;
  font: 400 16px sans-serif;
  cursor: pointer;
`;

export const RemoveFileButton = styled.button`
  display: flex;
  align-items: center;
  position: absolute;
  cursor: pointer;
  top: 10px;
  right: 10px;
  border-radius: 50%;
  background-color: white;
  width: 20px;
  height: 20px;
  padding: 0;
`;

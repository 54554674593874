import { useState } from "react";

import debounce from "shared/utils/debounce";
import { Input } from "shared/ui/formElements";
import { SearchWrapper } from "../styled";
import filterLegendDataByString from "./filterLegendDataByString";

const Search = ({ data, setFilteredList }) => {
  const [searchString, setSearchString] = useState("");

  const search = debounce((string) => {
    const filteredList = filterLegendDataByString({ data, string });
    setFilteredList(filteredList);
  }, 400);

  const onChange = ({ target }) => {
    const { value } = target;
    setSearchString(value);
    search(value);
  };

  return (
    <SearchWrapper>
      <Input
        type="search"
        placeholder="поиск по легенде"
        onChange={onChange}
        value={searchString}
      />
    </SearchWrapper>
  );
};

export default Search;

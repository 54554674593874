const typesOfLandPlots = {
  "003001000000": "Земли сельскохозяйственного назначения",

  "003001000010": "Сельскохозяйственные угодья",

  "003001000020":
    "Земельные участки, занятые внутрихозяйственными дорогами, коммуникациями, лесными насаждениями, предназначенными для обеспечения защиты земель от негативного воздействия, водными объектами, а также занятые зданиями, сооружениями, используемыми для производства, хранения и первичной переработки сельскохозяйственной продукции",

  "003001000030":
    "Прочие земельные участки из состава земель сельскохозяйственного назначения",

  "003002000000": "Земли населенных пунктов",

  "003002000010":
    "Земельные участки, отнесенные к зонам сельскохозяйственного использования",

  "003002000020":
    "Земельные участки, занятые жилищным фондом и объектами инженерной инфраструктуры жилищно-коммунального комплекса",

  "003002000030":
    "Земельные участки, приобретенные (предоставленные) для индивидуального жилищного строительства",

  "003002000040":
    "Земельные участки, приобретенные (предоставленные) на условиях осуществления на них жилищного строительства (за исключением индивидуального жилищного строительства)",

  "003002000060":
    "Земельные участки, приобретенные (предоставленные) для ведения личного подсобного хозяйства, садоводства и огородничества или животноводства, а также дачного хозяйства",

  "003002000090":
    "Земельные участки, отнесенные к производственным территориальным зонам и зонам инженерных и транспортных инфраструктур",

  "003002000110": "Земельные участки для обеспечения обороны",

  "003002000120": "Земельные участки для обеспечения безопасности",

  "003002000130": "Земельные участки для обеспечения таможенных нужд",

  "003002000100": "Прочие земельные участки",

  "003003000000":
    "Земли промышленности, энергетики, транспорта, связи, радиовещания, телевидения, информатики, земли для обеспечения космической деятельности, земли обороны, безопасности и земли иного специального назначения",

  "003003000010": "Земельные участки из состава земель промышленности",

  "003003000020": "Земельные участки из состава земель энергетики",

  "003003000030": "Земельные участки из состава земель транспорта",

  "003003000040":
    "Земельные участки из состава земель связи, радиовещания, телевидения, информатики",

  "003003000060": "Земельные участки из состава земель обороны",

  "003003000070": "Земельные участки из состава земель безопасности",

  "003008000010":
    "Земельные участки из состава земель,для обеспечения таможенных нужд",

  "003003000080":
    "Земельные участки из состава земель иного специального назначения",

  "003004000000": "Земли особо охраняемых территорий и объектов",

  "003005000000": "Земли лесного фонда",

  "003006000000": "Земли водного фонда",

  "003007000000": "Земли запаса",

  "003008000000":
    "Земельные участки, для которых категория земель не установлена",
};

export default typesOfLandPlots;
